.ATBRecommendation_container {
  .modal {
    &-content {
      .modal-footer {
        .btn.btn-secondary {
          background-color: $light-black;
          border-color: $light-black;
        }
        .btn-outline-secondary {
          color: $light-black;
          border-color: $light-black;
        }
        .btn-outline-secondary:hover,
        .btn-outline-secondary:active {
          color: $white;
        }
      }
    }
  }
}
