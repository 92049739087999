$media-mobile-min: 375px;
$media-mobile-max: 950px;
$media-breakpoint-tablet-min: 767px;
$media-breakpoint-tablet-medium: 820px;
$media-breakpoint-tablet-max: 1180px;
$media-breakpoint-tablet: 1024px;
$media-breakpoint-tablet-large: 1366px;

$landscape: 'landscape';
$portrait: 'portrait';

.header {
  max-width: none;
  &-sticky {
    border-bottom: none;
  }

  .header-item {
    @include media-breakpoint-up(md) {
      &__logo {
        padding-left: 0;
      }

      &__controls--right {
        padding-right: 0;
      }
    }

    &__menu {
      @include make-col(3);
      display: none;

      @media screen and (min-width: $media-breakpoint-tablet-medium)
        and (orientation: $portrait) {
        display: none !important;
      }

      .stores {
        display: none;
        @media screen and (min-device-width: $media-breakpoint-tablet-min) {
          display: block;
        }

        @media only screen
          and (min-device-width: $media-mobile-min)
          and (max-device-width: $media-mobile-max)
          and (orientation: $landscape) {
          display: none;
        }
      }

      @media only screen
        and (min-device-width: $media-breakpoint-tablet-min)
        and (orientation: $portrait) {
        display: block;
      }

      @media only screen
        and (min-device-width: $media-breakpoint-tablet-min)
        and (max-device-width: $media-breakpoint-tablet-max)
        and (orientation: $landscape) {
        display: none;
      }

      @media only screen
        and (min-device-width: $media-mobile-min)
        and (max-device-width: $media-mobile-max)
        and (orientation: $landscape) {
        display: block;
      }

      @include media-breakpoint-up(lg) {
        display: none !important;
      }
    }
  }

  .header-items {
    padding: rem-calc(8) rem-calc(16) rem-calc(4) rem-calc(16);
    align-items: center;
  }

  .header-element {

    .nav-menu__link__text {
      display: none;
    }

    &.minicart {

      position: relative;
      margin-right: 0;
      .minicart-link--active {
        .font-icon {
          color: $secondary;
        }
      }

      .minicart-label {
        display: none;
      }

      .minicart-round-element {
        position: absolute;
        font-size: 11px;
        margin-right: 0;
        top: 3px;
      }

      @include media-breakpoint-up(lg) {
        .minicart-link {
          display: flex;
          align-items: center;

          .minicart-icon__container {
            order: 2;

            .minicart-icon {
              margin-left: 0;
            }
          }

          .minicart-quantity {
            order: 1;
            display: inline-block;
          }
        }
      }
      .minicart-quantity {
        color: $black;
        width: rem-calc(20);
        display: inline-block;
        text-align: center;
      }
    }

    &__menu {
      line-height: rem-calc(20); // TODO: Figure out why this became necessary after removing the unused icons from Lindua
    }

    @include media-breakpoint-only(md) {
      &.user {
        .font-icon {
          font-size: rem-calc(33);
        }
      }
    }

    @include media-breakpoint-up(lg) {
      &__icon {
        &.minicart {
          order: 3;
          margin-left: map-get($spacers, 1);
        }
      }

      &.wishlist {
        .font-icon {
          font-size: rem-calc(18);
        }
        .header-element__text {
          display: none;
        }
      }

      &.header-element__user.user {
        color: red;
        margin-right: 8px;
        @media only screen and (min-device-width: $media-breakpoint-tablet-min) {
          margin-right: 16px;
        }
        .font-icon {
          font-size: rem-calc(21);
        }
      }

      &.stores {
        .font-icon {
          font-size: rem-calc(19);
        }
      }
      &.minicart {
        .font-icon {
          display: inline-block;
          font-size: rem-calc(24);
          margin-bottom: 0;
          margin-left: rem-calc(3);
        }

        .minicart-total {
          margin-left: 0;
        }
      }
    }
  }
  .right-container {

    max-height: 30px;
    .inner-container {
      justify-content: flex-end;
      align-items: center;
    }

    @include make-col(4);

    @media only screen and (max-device-width: $bp-medium)
      and (orientation: $portrait) {
      @include make-col(3);
    }

    @include media-breakpoint-up(lg) {
      padding-left: rem-calc(100);
    }

    .wishlist {
      @media only screen and (min-device-width: $media-breakpoint-tablet-min) {
        display: block;
        margin-right: rem-calc(16);
        img {
          margin-right: 0;
        }
      }
    }

    .header-element__text {
      display: none;
    }

    .stores {
      order: -1;
      display: none;

      @media only screen
        and (min-device-width: $media-breakpoint-tablet-min)
        and (max-device-width: $media-breakpoint-tablet-max)
        and (orientation: $portrait) {
        margin-right: 0;
        display: none;
      }

      @media screen and (min-width: $media-breakpoint-tablet-medium) and (orientation: $portrait) {
        display: block;
        margin-right: 16px;
      }

      @include media-breakpoint-up(lg) {
        display: block;
        margin-right: 16px;
      }
    }

    .header-item__search.right {
      @include media-breakpoint-up(sm) {
        display: none !important;
      }
    }
  }

  &-banner {
    &.slide-up {
      &:not(.header-banner-bottom) {
        padding: 0;
      }
      .banner-container {
        @media only screen and (min-width: $media-breakpoint-tablet-min) {
          margin-left: 16%;
          flex: 0 0 auto;
          max-width: 68%;
        }
      }
    }
  }
}

.site-search {
  &__controls {
    .search-button {
      right: 0;
      top: auto;
      bottom: 0;
      border-left: none;
    }
    .clear-search {
      color: $neutral-grey;
      font-size: 13px;
      line-height: 19.6px;
    }
  }

  input.form-control {
    border: 0;
    border-bottom: 1px solid #bdbdbd;
    background-color: white;
    letter-spacing: rem-calc(0.52);
    font-size: 16px;
    padding-right: rem-calc(130);

    @media screen and (max-width: $media-breakpoint-tablet-min) {
      background-color: #f3f3f3;
      border-bottom: none;
    }
    @media screen and (min-width: $media-breakpoint-tablet-medium) and (orientation: $portrait) {
      &:placeholder-shown {
        padding-right: rem-calc(80);
      }
      padding-right: rem-calc(136);
    }

    &::placeholder {
      color: $neutral-grey;
      line-height: rem-calc(19.6);

      @include media-breakpoint-up(lg) {
        font-size: 13px;
      }
    }

    @include media-breakpoint-up(md) {
      font-size: 13px;
    }
  }
}

header.js-is-sticky {
  .header {
    input.form-control {
      border-bottom: 1px solid #bdbdbd;
    }
  }
}

.header-element.logo {

  margin-right: 0;
  .navbar-header {
    padding-left: 0 !important;
  }

  img {
    max-width: rem-calc(160);
    height: rem-calc(30);
    margin: auto;

    @media only screen and (min-device-width: $bp-medium) {
      max-width: 184px;
      height: 34.2px;
    }

    @media screen and (min-width: $media-breakpoint-tablet-medium) and (orientation: $portrait) {
      margin: 0;
    }

    @include media-breakpoint-up(lg) {
      margin: 0;
    }
  }

  @include make-col(6);

  @include media-breakpoint-down(sm) {
    @include make-col(5);
  }

  @include media-breakpoint-only(md) {
    @include make-col(4);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(4);
  }

  @include media-breakpoint-up(sm) {
    &.left {
      display: none !important;
    }
  }

  &.center {
    display: block !important;
    @media only screen
      and (min-device-width: $media-breakpoint-tablet-min)
      and (max-device-width: $media-breakpoint-tablet-max)
      and (orientation: $portrait) {
      order: 0;
      margin: 0;
    }
  }
}

.primary-category-slider {
  padding: rem-calc(8) rem-calc(16) rem-calc(8) rem-calc(16);

  @media only screen and (orientation: $landscape) {
    display: none;
  }

  @media only screen
    and (min-device-width: $media-breakpoint-tablet-min)
    and (max-device-width: $media-breakpoint-tablet-max)
    and (orientation: $portrait) {
    text-align: center;
  }

  @media only screen and (min-width: $media-breakpoint-tablet)
    and (max-width: $media-breakpoint-tablet-large)
    and (orientation: $portrait) {
    display: none;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19.6px;
    text-transform: lowercase;
    display: inline-block;
    color: $dark-background;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.header .minicart-icon__container {
  max-height: 24px;
  .minicart-icon {
    content: url('../../../../../cartridge/static/default/images/minicart.svg') !important;
    display: block;
    height: 24px;
    width: 24px;
    margin-bottom: 0;
  }
}

.header-item__search {
  &.lower {
    @include media-breakpoint-up(md) {
      display: none;
    }

    @media only screen and (min-width: $media-breakpoint-tablet)
      and (max-width: $media-breakpoint-tablet-large)
      and (orientation: $portrait) {
      display: none;
    }

    @media screen and (min-width: $media-breakpoint-tablet-medium)
      and (orientation: $portrait) {
      display: none;
    }

    .search-field {
      padding-left: 16px;
    }
    .search-button {
      padding-right: 16px;
    }
  }
  &.center {
    display: none;

    @media screen and (min-width: $media-breakpoint-tablet-medium) and (orientation: $portrait) {
      display: block !important;
    }

    @include media-breakpoint-up(lg) {
      display: block;
    }
    @media screen and (min-width: $media-breakpoint-tablet-min) and (orientation: $landscape) {
      display: block;
    }
    @include media-breakpoint-between(sm, md) {
      display: none;
    }
  }
}

.nav-menu__items.nav-menu__items--level-1 {
  border-top: none;
}

.nav-menu:not(.nav-menu--visible) .nav-menu__items--level-1 {
  display: inline-block;
  line-height: 1;
  span {
    font-weight: 400;
    color: $dark-background;
    text-transform: capitalize;
  }

  .nav-menu__items--level-2,
  .nav-menu__items--level-3 {
    text-transform: none;
  }
}

@include media-breakpoint-down(md) {
  .suggestions {
    .suggestions__content {
      &--categories {
        padding-left: 10px;
      }
      ul {
        padding-left: 0;
        li {
          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
  }
}

.product-detail__product-bottom {
  .product-recommendations__heading {
    margin: 0  0 rem-calc(24) 0;
    @include media-breakpoint-up(lg) {
      margin: 0  0 rem-calc(56) 0;
    }
  }
  .space-bottom {
    margin-bottom: rem-calc(56);
    @include media-breakpoint-up(lg) {
      margin-bottom: rem-calc(88);
    }
  }
  .recommendation-carousel-pdp {
    .product-detail__tile-row__item {
      flex: 0 0 auto;
      max-width: 100%;
    }
  }
}

.js-page-locale-loader {
  @media screen and (min-width: $media-breakpoint-tablet-min) {
    flex: 0 0 auto;
    max-width: 16%;
  }
  padding-right: 16px;
  .country-selector,
  .country-selector-link {
    .country-selector__current__country {
      .flag-icon {
        background-repeat: repeat;
        height: 20px;
        width: 20px;
        vertical-align: middle;
        border-radius: 100px;
      }
    }
  }
}

.country-selector {
  &__current {
    @media only screen and (min-width: $media-breakpoint-tablet-min) {
      display: block !important;
    }
  }
}

.header-element.header-element__user.user {
  @media screen and (min-width: $media-breakpoint-tablet-min) {
    margin-right: 16px;
  }
}

.nav-menu:not(.nav-menu--visible) {
  .nav-menu__items--level-1 > .nav-menu__item > .nav-menu__item-link > .nav-menu__item-link-name {
    font-size: 16px;
  }
}
