.heading {
  @extend .rd-featureTile-heading;
  text-align: center;
  margin-bottom: 1rem;
  font-family: "Chronicle Display";
  @include media-breakpoint-down(sm) {
    font-size: 1.5rem;
  }
}

.USPwide_content {
  background: #f8f6f3;
  padding: 0 0 3.125rem 0;

  @media (max-width: $bp-tablet-sm-max) {
    padding-left: 1rem;
  }

  @media (min-width: $bp-tablet-med) {
    margin-left: 6.5rem;
  }

  .tile_content {
    overflow-x: auto;
    scrollbar-width: none;
  }

  @include media-breakpoint-down(md) {
    padding: 0 1rem 2.5rem 1rem;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 0 2.5rem 1rem;
  }

  .tile_info {
    padding: 0 !important;
    max-width: 100%;
  }

  img {
    width: 100%;
  }

  .img-section {
    @include media-breakpoint-down(md) {
      margin-bottom: 0.5rem;
    }
    margin-bottom: 0.75rem;
  }

  .title {
    @extend .rd-uspTitletext;
    margin-bottom: 0.25rem;
  }

  .text-section {
    @extend .rd-uspInfoText;
    opacity: 0.6;
  }
}

.USPwide {
  position: relative;
  background: #f8f6f3;
  margin-bottom: 3.125rem;

  @media (max-width: $bp-medium-max) {
    margin-bottom: 2.5rem;
  }

  h1.heading {
    padding-top: 3.125rem;
    padding-bottom: 1rem;
    margin-bottom: 0;
    background-color: #f8f6f3;

    @media (max-width: $bp-medium-max) {
      padding-top: 2.5rem;
    }
  }
  .glide__arrows {
    display: flex;
    justify-content: space-between;
    width: 100%;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
  }

  .glide__arrow {
    cursor: pointer;
    box-shadow: none;

    &.glide__arrow--left {
      left: 6.5rem;
      background-color: transparent;
      border: none;
      background-image: url('../../../../static/default/images/icons/chevron_left.svg');
    }

    &.glide__arrow--right {
      background-color: transparent;
      border: none;
      background-image: url('../../../../static/default/images/icons/chevron_right.svg');
    }

    &:hover {
      border: none;
      background-color: transparent;
    }

    @media (max-width: $bp-med-max) {
      display: none;
    }

    &.glide__arrow--disabled {
      &:hover {
        opacity: 0.33;
      }
    }
  }
}
