.account-dashboard {
  .account-nav {
    &__item {
      &-icon {
        @include make-col-ready();
        @include make-col(2);

        @include media-breakpoint-up(lg) {
          max-width: 25%;
        }
      }
      &-text {
        @include make-col-ready();
        @include make-col(8);
      }

      &--logout {
        border-bottom: none;
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
          text-transform: capitalize;
        }

        .account-nav__item-icon {
          visibility: hidden;
        }
        .account-nav__item-text {
          text-decoration: underline;
        }
      }
    }
  }
  .remove-from-wishlist {
    color: $light-black;
  }
}
