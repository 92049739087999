.store-locator-container {
  .store-locator-filter-result-container {
    margin-top: 40px;
  }
  .stores-details-container {
    height: 38.5rem;
  }
  .store-locator {
    &__spacers--first {
      margin-top: 0;
    }
    &__subheaders {
      .marker-container {
        .icon-map-marker {
          display: inline-flex;
          height: 28px;
          width: 20px;
          background-repeat: no-repeat;
          background-size: unset;
          background-position: -8px -4px;

          &::before {
            content: "";
          }
        }

        .standalone {
          background-image: url('../../../../static/default/images/map-pin-default.svg');
        }

        .stockist {
          background-image: url('../../../../static/default/images/map-pin-concession.svg');
        }

        .outlet {
          background-image: url('../../../../static/default/images/map-pin-outlet.svg');
        }

        span {
          height: 100%;
          vertical-align: middle;
          display: inline-block;
          margin-top: -5px;
        }
      }
    }
  }
  .custom-checkbox {
    margin-bottom: 10px;

    .custom-control-label {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.7px;
      left: 1rem;

      &::before {
        height: 21px;
        width: 21px;
        top: -0.1rem;
        left: -2rem;
      }

      &::after {
        height: 21px;
        width: 21px;
        top: -0.1rem;
        left: -2rem;
      }
    }
  }
}

.store-details-page-container {
  .holiday-hours-container {
    background-color: $light-black;
  }
}
