$media-breakpoint-tablet-wide: 1024px + 1;
$bp-large: 992px !default;

.search-results,
.search-results-container,
.search-header__content,
.search-header__breadcrumbs {
  max-width: none;
  .breadcrumb {
    padding-left: 0;
    padding-right: 0;
    @include media-breakpoint-up(md) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
}

@include media-breakpoint-down(md) {
  .nav-menu__header {
    .nav-menu__header__logo-row {
      justify-content: end;
    }

    .nav-menu__header__logo {
      text-align: center;
    }

    .nav-menu__header__close {
      flex: 0 0 26%;
      max-width: 100%;
    }
  }
}

.horizontal-filter-container {
  @media only screen and (min-width: $media-breakpoint-tablet-wide) {
    display: flex;
  }
  .filters {
    &__items {
      flex-wrap: wrap;
    }

    &__body {
      overflow: unset;
    }
    .apply-button-container,
    .close-horizontal-dropdown {
      display: none;
    }
    @include media-breakpoint-up(lg) {
      &__filter-header {
        padding: 0.5rem 0;
        position: relative;
        margin-right: 24px;
        &__text {
          @include horizontal-filter-header-text();
        }
      }
      &__section__btn-icon {
        top: 0.875rem;
        right: -0.75rem;
        margin-right: -8px;
        margin-top: 0;
      }
      .close-horizontal-dropdown {
        width: 1.5rem;
        height: 1.5rem;
        right: 0.75rem;
        position: absolute;
        cursor: pointer;
        display: block;
      }
      .apply-button-container {
        display: flex;
        justify-content: right;
        .apply-button {
          width: 9.313rem;
          height: 2.25rem;
          display: flex;
          font-size: 1rem;
          cursor: pointer;
          margin-right: 10px;
          align-items: center;
          line-height: 1.75rem;
          color: $neutral-grey;
          justify-content: center;
          letter-spacing: 0.014rem;
          background-color: $neutral-grey-5;
        }
        &.active span {
          color: $white;
          background-color: $light-black;
        }
      }
    }
    .filters__filter-header__text {
      @extend .rd-horizonral-bar-text;
    }
    @include media-breakpoint-up(lg) {
      &__items {
        display: flex;
      }
    }

    &__filter {
      @media only screen and (min-width: $bp-large) {
        margin-right: 25px;
      }
      @include media-breakpoint-down(md) {
        .filters__section__btn-icon {
          right: 0;
          top: 20px;
          z-index: 1;
        }
      }
      &-body {
        @media only screen and (min-width: $bp-large) {
          position: absolute;
          top: 2.125rem;
          left: 0;
          right: 0;
          background-color: white;
          z-index: 2;
          margin-left: 0.375rem;
          padding-bottom: 1.5rem;
          &.collapse,
          &.collapse.show,
          &.collapsing {
            transition: none !important;
          }
          &.keep-open {
            display: block;
          }
          ul {
            display: grid;
            grid-auto-flow: column dense;
            grid-auto-columns: max-content;
            grid-template-columns: none;
            grid-template-rows: repeat(3, auto);
            width: 100% !important;
            padding: 1rem 1rem 0 0;
            overflow: scroll;
            scrollbar-width: none;
          }
        }
      }
      @media only screen and (min-width: $bp-large) {
        &--applied-filters {
          display: none;
        }
        &--size,
        &--shoesize {
          .filters__value-text {
            padding: 0;
            border: none;
            margin-top: 2px;
          }
        }
      }

      .filters__value-name,
      .filters__value-count {
        line-height: 1.225rem;
        font-size: 0.8125rem;
        letter-spacing: 0.040625rem;
        color: $dark-background;
      }
    }
    &__value {
      @media only screen and (min-width: $bp-large) {
        margin-bottom: 1rem;
        padding-right: 4rem;
        &-text--not-one-size {
          max-width: 100%;
          width: unset;
        }
      }
    }
  }
  .show-more {
    display: block;
  }
  @media only screen and (min-width: $bp-large) {
    .show-less {
      display: none;
    }
  }
  .show-container {
    @media only screen and (max-width: $sm-width) {
      display: none;
    }
  }
}

.search-content .search-results__controls .horizontal-filter {
  padding: 0;
  @media only screen and (max-width: $sm-width) {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
  }
  @media only screen and (min-width: $media-breakpoint-tablet-wide) {
    display: flex;
    max-width: 75%;
    width: 100%;
  }
  .sort-toggle.side-col {
    width: 100%;
    max-width: 100%;
  }
  @include media-breakpoint-up(lg) {
    padding: 0;
    background-color: $white;
    #filters-container {
      transition: none;
      margin-right: 0;
    }
    .sorting {
      &__toggle-section {
        &__btn {
          padding: 0 !important;
        }
      }
    }
  }
}

.search-content .search-results__controls .horizontal-filter-container {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  #filters-container {
    width: 100%;
    max-width: 100%;
  }
  @media only screen and (min-width: $bp-large) and (max-width: $bp-tablet-med) {
    border-bottom: 1px solid $neutral-grey-5;
  }
}

.right-wrapper {
  display: none;
  @media only screen and (min-width: $bp-large) and (max-width: $bp-tablet-med) {
    justify-content: start !important;
    max-width: 50% !important;
    padding: 0.025rem 0;
  }
  @media only screen and (min-width: $bp-large) {
    justify-content: end;
    max-width: 25%;
    flex: 0 0 auto;
    padding: 0.5rem 0;
  }
  .page-size-toggle {
    margin-top: 0;
  }
  @media only screen and (min-width: $bp-large) {
    display: flex;
    .sort-toggle {
      max-width: 10rem;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .results-count {
    &__separator,
    &__page-sizes,
    &--desktop {
      display: none;
    }
    &--mobile {
      display: block;
      @media only screen and (min-width: $bp-large) {
        margin-right: 2rem;
      }
    }
  }
  .sorting {
    margin-top: 0;
    &__section__btn {
      padding: 0 !important;
      &-wrapper {
        &::after {
          background: none;
        }
      }
    }
    &__toggle-section__btn {
      font-size: 13px !important;
      @media only screen and (min-width: $bp-large) {
        padding: 0 30px 0 8px !important;
      }
      @include horizontal-filter-header-text();
      &-wrapper {
        padding-right: 0;
        .form-icon {
          top: -4px;
          right: 0;
          &::after {
            @media only screen and (min-width: $bp-large) {
              height: 0;
            }
          }
        }
      }
    }
  }
}

.filters {
  &__section {
    &__btn {
      @include media-breakpoint-up(md) {
        border-top: none;
      }
    }
  }

  &__form {
    &--price {
      width: 75%;
    }
  }
}

.horizontal-container {
  @media only screen and (min-width: $bp-large) {
    .paging-container--top {
      display: none;
    }
    .image-type-toggle-wrapper {
      flex-grow: 1;
      max-width: 100%;
      justify-content: space-around;
    }
  }
  .search-results__controls {
    @media only screen and (min-width: $bp-large) {
      padding: 1rem 0.375rem 0.25rem 0.375rem;
      position: sticky;
      top: 5.188rem;
      background-color: white;
      z-index: 2;
      .applied-filter-text {
        margin-right: 1rem;
        @include horizontal-filter-header-text();
        font-weight: 600;
      }
    }
    @media only screen and (min-width: $bp-large) and (max-width: $media-breakpoint-tablet) {
      flex-direction: column;
    }
    .applied-refinements-container {
      margin-bottom: 0;
      align-items: center;
      .full-border {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        margin-left: -1rem;
        margin-right: -1rem;
        width: calc(100% + 2rem);
        border-top: 1px solid $neutral-grey-5;
        overflow: hidden;
      }

      &:has(ul) {
        padding: 1rem 0 1rem 0.125rem;
        &.desktop {
          width: 100%;
          position: relative;
          margin-top: 0.25rem;
        }
      }
      &.desktop {
        @media only screen and (min-width: $bp-large) {
          display: flex !important;
        }
        .clear-all {
          margin-top: unset;
          position: initial;
          @extend .rd-horizonral-bar-text;
        }
      }
      ul {
        margin-bottom: 0;
      }
      .span {
        @include horizontal-filter-header-text();
        margin-right: 1rem;
      }
      .filters__reset-link {
        text-decoration: underline;
        @include horizontal-filter-header-text();
        font-weight: 600;

        @media (min-width: $bp-large) {
          text-transform: lowercase;
          display: inline-block;

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
    }
    .filters {
      &__filter {
        &__applied {
          @media only screen and (min-width: $bp-large) {
            margin-bottom: 0;
            padding: 0.375rem 0.5rem !important;
            margin-right: 12px;
            border: 1px solid $dark-background;
            @include horizontal-filter-header-text();
            &__text {
              @include horizontal-filter-header-text();
            }
            .font-icon {
              font-size: 1rem;
              color: $neutral-grey;
              position: relative;
              top: 2px;
            }
          }
        }
        &.filters__section {
          @media only screen and (min-width: $bp-large) {
            &.active {
              .show-icon {
                display: none;
              }
              .hide-icon {
                display: block;
                color: $dark-background;
                font-size: 0.875rem;
              }
              .filters__filter-header__text {
                font-weight: 600;
              }
            }
            &:not(.active) {
              .hide-icon {
                display: none;
              }
              .show-icon {
                display: block;
                color: $neutral-grey;
                font-size: 0.875rem;
              }
            }
          }
        }
      }
      &__value-link {
        &::before {
          width: 1.5rem;
          height: 1.5rem;
          border: 1px solid $neutral-grey-5;
          margin-right: 0.5rem;
        }
        &.selected::before {
          padding-top: 0.125rem;
          padding-left: 0.25rem;
          border: 1px solid $dark-background;
          color: $dark-background;
        }
        @media only screen and (min-width: $bp-large) {
          &--size {
            display: flex;
            &.selected::before,
            &::before {
              display: block;
            }
          }
        }
      }
    }
    .show-more-container {
      display: none;
      @extend .rd-horizonral-bar-text;
      @media only screen and (min-width: $bp-large) {
        padding: 0.5rem 0;
        display: flex;
        align-items: center;
        font-weight: 600 !important;
      }
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .search-results__count-row__image-type-toggle {
    display: none;
  }
}

.search-results {
  .search-results-container {
    .search-results__controls {
      padding-bottom: 0;
    }
  }
}

.product-grid-container {
  margin-top: 0.4375rem !important;
}

.product-tile__image {

  @media (max-width: $bp-tablet-sm-max) {
    overflow: visible;

    .primary-images__controls.carousel-indicators {
      bottom: 1rem;

      li {
        opacity: 0.5;
        &.active {
          opacity: 1;
        }
      }
    }

    .image-container {
      .plp-image-container {
        display: flex;
        .carousel-item {
          flex: 0 0 auto;
        }
      }
    }

    .carousel {
      aspect-ratio: 5 / 7; // stylelint-disable-line
    }
  }

  .primary-images__controls.carousel-indicators {
    @media (min-width: $bp-tablet-med) {
      display: none;
    }
  }

  .product-tile__action-add {
    height: 2.25rem;
    max-height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 1.75rem;
    letter-spacing: 0.00875rem;

    @media (max-width: $bp-tablet-med) {
      position: relative;
      bottom: 0;
    }
  }

  .product-tile__action {
    background-color: rgba(224, 224, 224, 1);
    border-color: rgba(224, 224, 224, 1);
    &:active {
      background-color: rgba(255, 255, 255, 0.6);
      border-color: rgba(255, 255, 255, 0.6);
    }
  }

  .product-tile__add-overlay {
    .product-tile__add-overlay-size-btn {
      .product-detail__add-to-cart .add-to-cart,
      .continuity-flyout .continuity-flyout__button .desktop-message {
        font-size: 0.625rem;
        font-weight: 400;
        line-height: 1.75rem;
        letter-spacing: 0.00875rem;
        height: 2.25rem;
      }

      .continuity-flyout {
        height: 2.25rem;

        .continuity-flyout__button {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 2.25rem;
        }
      }
    }
  }
}

.product-tile__add-overlay-size-input:checked + .product-tile__add-overlay-size + .product-tile__add-overlay-size-btn {
  .add-to-cart {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .continuity-flyout .desktop-message {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
