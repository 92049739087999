$icon-font: 'PhaseEightIconFont';

$gold: #a79272;
$neutral-grey-5: #e0e0e0;
$light-black: #1d1d1d;
$grey-5:rgba(29, 29, 29, 0.6);
$grey-6:rgba(29, 29, 29, 0.4);
$overlay-white:rgba(255, 255, 255, 0.5);
$light-gray-90-opacity: rgba(249, 249, 249, 0.9);
$brown:#ac8c87;
$light-dark: #4f4f4f;
$light-red: #d93831;
$dark-red: #bb271a;
$red-promo: #ff0211;
$slakelime: #f8f6f3;
$dark-charcoal: #333;
$secondary: $light-black;
@import '~core-css/variables';
