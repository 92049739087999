.product-detail--set {
  .set-top-section {
    @media (min-width: $bp-desktop-min) {
      margin: auto;
      max-width: 70rem;
      padding-bottom: 2.5rem;
    }
    .style-with-CTA {
      @media (max-width: $bp-medium-max) {
        z-index: 8;
      }
    }
  }
  .container {
    max-width: none;
  }
  .product-detail__set-container {
    @media (max-width: $bp-medium-max) {
      padding: 0 1rem;
    }

    @media (min-width: $bp-large) and (max-width: $bp-large-max) {
      padding-left: 2rem;
      padding-right: 1rem;
    }

    @media (min-width: $bp-desktop-min) {
      padding-left: 2.5rem;
      padding-right: 0.3125rem;
    }
  }

  .breadcrumb-top {
    @media (max-width: $bp-large) {
      margin-bottom: 1rem;
    }
    .product-detail__nav-row {
      @media (min-width: $bp-large) {
        margin: auto;
        max-width: 70rem;
        padding-top: 0.5rem;
        padding-bottom: 1.5rem;
      }
      @media (max-width: $bp-large) {
        margin: auto;
        padding-top: 0.75rem;
      }
    }
    @media (max-width: $bp-large) {
      .breadcrumb-container {
        padding: 0 1rem;
        font-size: 11px;
      }
    }
  }
  .product-detail__details-section--product {
    flex: 0 0 50%;
    max-width: 50%;

    @media (max-width: $bp-large) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .container .product-detail__product-carousel {
    @media (min-width: $bp-desktop-min) {
      padding-left: 5rem;
      padding-right: 5rem;
    }

    @include media-breakpoint-down(md) {
      padding-left: 1rem;
      padding-top: 0 !important;
      padding-bottom: 2.5rem !important;
    }

    @media (min-width: $bp-large) and (max-width: $bp-med-max) {
      margin-left: 0;
      padding-right: 2rem;
      padding-left: 2rem;
      padding-top: 4rem !important;
      padding-bottom: 0 !important;
    }
  }

  .product-detail__product-carousel:has(.product-detail__recently-viewed) {
    padding-left: 0;
    margin-left: 0.5rem;
    margin-right: 0;

    @media (min-width: $bp-large) and (max-width: $bp-med-max) {
      padding-right: 1.375rem;
      padding-left: 1.375rem;
      margin-left: 0;
    }

    @media (min-width: $bp-desktop-min) {
      padding-left: 4.375rem;
      padding-right: 4.375rem;
      margin-left: 0;
    }
  }

  .product-detail__tile-row-container {
    .einsteinCustomStyles {
      position: relative;

      .product-recommendations__heading {
        margin-top: 0;
      }
    }
  }

  .backToTop {
    margin-top: 4rem;
    margin-bottom: 4rem;
    @media (max-width: $bp-medium-max) {
      margin-top: 0;
      margin-bottom: 1.5rem;
    }
  }
}

.product-detail__details-section--set {
  @include media-breakpoint-down(md) {
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 0.5rem;
  }

  @media (min-width: $bp-desktop-min) {
    padding-right: 0 !important;
  }

  .product-detail__product-images {
    padding-right: 0;
  }

  .product-detail__stock-check {
    padding: 0;
    margin-bottom: 0;
    @media only screen and (max-width: $bp-large) {
      margin-top: 0.8rem;
    }

    .product-detail__stock-check__link {
      padding: 0;

      .product-detail__stock-check__link__text {
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .select-dropdown__list.active {
    @media (min-width: $bp-large) {
      z-index: 1;
    }
  }

  .product-detail__brand-name + a {
    display: inline-block;
    width: fit-content;
  }

  .product-detail__fit-rating {
    @media (min-width: $bp-large) {
      .fit-labels {
        .d-lg-none {
          display: block !important;
        }
        .d-lg-block {
          display: none !important;
        }
      }
    }
  }
  .sizeguidelabel {
    letter-spacing: 0.15rem;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 0.9rem;
    text-transform: uppercase;
  }
}

.new-pdp {
  .product-set-detail {
    .header-banner-bottom-new {
      .header-banner-global-bottom {
        display: block;
      }
    }
  }
}

.product-detail {
  .product-set-main-image-sticky {
    text-align: center;
    @media (min-width: $bp-large) {
      position: sticky;
      top: rem-calc(120px);
    }
    @media (min-width: $bp-desktop-min) {
      padding-right: 2.5rem;
      padding-left: 0;
    }

    .header-banner-bottom-new .header-banner-global-bottom:has(> div:not(.carousel), p, a) {
      margin: 0;
      height: 1.5rem;
      padding: 0 1rem;
    }
  }

  &__item-link {
    display: none;
  }

  .set-items {
    .product-detail__product-images {
      @media (min-width: $bp-large) {
        padding-right: 0;
        padding-bottom: 0;
      }
      @media (max-width: $bp-large) {
        width: 100%;
      }
      .carousel-indicators {
        display: flex;
        bottom: 0;
        @media (min-width: $bp-large) {
          display: none;
        }

        li {
          border-radius: 100%;
          border: none;
          width: 0.4375rem;
          height: 0.4375rem;
          &.active {
            background-color: $mid-grey;
          }
        }
      }
      .carousel-control-prev,
      .carousel-control-next {
        bottom: 6%;
        color: $light-black;
        @media (max-width: $bp-large) {
          display: none;
        }
      }
      .third-image {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0;
        margin-bottom: 1em;
      }
    }

    .product-detail__attribute--size {
      @media (min-width: $bp-large) {
        width: 100%;
        padding-bottom: rem-calc(8);
      }
    }
    .product-detail .cart-and-ipay {
      @media (min-width: $bp-large) {
        width: 100%;
      }
      @media (max-width: $bp-large) {
        .btn.btn-secondary {
          top: 0;
        }
      }
    }

    .product-detail__attribute__display-value {
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.42px;
      line-height: 21px;
    }

    .product-detail .addtobagbtn .add-to-cart,
    .product-detail .addtobagbtn .continuity-flyout__button {
      @media (min-width: $bp-large) {
        left: 0;
      }
    }

    .product-detail__stock-check__link {
      border: none;
      display: flex !important;
      align-items: center;
      margin-bottom: 0.5rem;

      .icon-chevron-right {
        margin-left: 0.3125rem;
      }
    }

    .set-item {
      padding-right: 0;
      padding-left: 0;
      margin-bottom: rem-calc(48px);

      @media (max-width: $bp-medium-max) {
        margin-bottom: 2.5rem;
      }

      .product-detail__product-name {
        @extend .rd-set-page-product-title;
        margin-top: 0;
        @media (max-width: $bp-large) {
          margin-top: 0.5rem;
        }
      }
      .product-detail__ratings {
        cursor: default;
      }
      .primary-images__wrapper.carousel-inner.row.no-gutters {
        @media (min-width: $bp-large) {
          margin-top: 0;
        }
      }
      .carousel-item.active {
        transition: transform 0.25s ease;
      }

      .product-detail__attribute__error {
        position: relative;
        bottom: 4px;
      }

      .set-item-product-section {
        margin-left: 0;
        margin-right: 0;
        .product-detail__details-section {
          padding: 0;
          @media (max-width: $bp-large) {
            padding: 0;
          }
        }
      }

      .product-detail__prices {
        .has-strike-through,
        .strike-through {
          line-height: 28px;
        }
        .has-strike-through {
          color: $red-promo;
          line-height: 16.8px;
        }
      }

      .promoSection {
        margin-bottom: 1rem;
      }

      .attribute_section .color-section {
        margin-bottom: 1rem;
      }

      .product-detail__fit-rating {
        margin-bottom: 1.5rem;
      }
    }

    .primary-images {
      @media (max-width: $bp-large) {
        width: 100%;
      }
    }

    img {
      &.primary-images__image.cld-responsive {
        width: 100%;
      }
    }

    .primary-images__carousel {
      width: 100%;
    }

    .primary-images__image-wrapper__inner {
      padding-right: 0;
    }

    @media (min-width: $bp-large) {
      img {
        &.primary-images__image.cld-responsive {
          height: auto;
        }
      }

      .primary-images__carousel {
        height: auto;
        width: 150px;
      }

      .primary-images__image-wrapper__inner {
        padding-right: 0;
      }
    }
  }

  &--set & {
    &__set-heading {
      @extend .rd-set-page-heading;
      margin-bottom: 1rem;
      text-align: left;
    }

    &__set-sub-heading {
      @extend .rd-set-page-sub-heading;
      text-align: left;
      margin-bottom: 2rem;
      visibility: visible;
      &::after {
        content: '';
        visibility: hidden;
      }
      @media (max-width: $bp-medium-max) {
        margin-bottom: 1rem;
      }
    }
  }
}
