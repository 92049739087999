.login {
  &__tab-items,
  &__tab-panes {
    @include media-breakpoint-up(lg) {
      @include make-col(8);
    }

    @include media-breakpoint-up(xl) {
      @include make-col(6);
    }
  }
}
