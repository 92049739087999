.product-detail__stock-check__pane {
  .product-detail__stock-check__container {
    padding: 0;
    scrollbar-width: none;
    .form-icon {
      display: none;
    }
    max-width: 22.438rem;
    @media only screen and (min-width: $md-width) {
      max-width: 23.438rem;
    }
    .store-search-field {
      span {
        position: absolute;
        z-index: 1;
        left: 1rem;
        top: 12px;
      }
      label {
        left: 3rem;
      }
      input[type="text"] {
        border: 1px solid rgba(29, 29, 29, 0.2);
        &.is-valid:not(select):not([disabled]) {
          border: 1px solid $light-dark;
          &:not(.is-invalid) {
            ~ .form-icon {
              display: none !important;
            }
          }
        }
        &.is-invalid:not(select):not([disabled]) {
          border: 1px solid $dark-red;
        }
        &:not(:placeholder-shown) {
          border: 1px solid $light-dark;
        }
      }
      .invalid-feedback {
        @extend .rd-store-invalid-text;
      }
      .form-icon {
        display: none;
      }
      .rd-store-icon {
        top: 12px;
        left: 1rem;
        width: 1.5rem;
        height: 1.5rem;
        border: none;
        background-color: transparent;
        :focus {
          outline: unset;
        }
      }
      &:has(.focus-visible) {
        .rd-store-icon {
          display: none;
        }
        label {
          left: 1rem;
        }
        input {
          padding-left: 1rem;
        }
      }
    }
  }
  .product-detail__stock-check {
    &__form {
      padding: 0 1rem;
      &__store {
        margin-top: 0;
      }
      &__attribute {
        &--fitType,
        &--color {
          display: none;
        }
        &-container {
          margin: 0;
        }
        &--size {
          margin-bottom: 1.5rem !important;
        }
      }
      &__fields__background {
        display: none;
      }
    }
    &__close {
      padding-top: 1rem;
      padding-right: 1rem;
      position: sticky;
      top: 0;
      z-index: 4;
      .icon-cross {
        font-size: 1rem;
      }
    }
    &__swatch-label {
      padding-left: 0;
      padding-bottom: 0.5rem;
      @extend .rd-store-name;
    }
  }
  .attribute_current {
    border-bottom: 1px solid $black;
  }
  .dropdown-swatch {
    img {
      cursor: pointer;
      width: 1.875rem;
      height: 1.188rem;
    }
    padding-bottom: 0.25rem;
    margin-right: 0.438rem;
  }
  .store-search-field,
  .countryField-holder {
    .form-group {
      margin-bottom: 1rem;
    }
    .form-icon {
      display: block;
      right: 1rem !important;
      top: 0.375rem;
    }
    input {
      height: 3rem;
      padding-left: 3rem;
      &:focus {
        border: 1px solid grey;
      }
    }
  }
  .countryField-holder {
    label {
      display: none;
    }
  }
  .input-select {
    height: 3rem;
    padding: 0 0 0 1rem;
    border: 1px solid $light-dark;
    &:focus {
      border: 1px solid $light-dark;
    }
    @extend .rd-store-form-text;
  }
  .size-dropdown {
    .form-icon {
      right: 1rem;
      display: block;
      margin-top: 0.875rem;
    }
  }
  .btn-storelocator-search {
    height: 2.5rem;
    border: none;
    background-color: $light-black;
    padding: 0.5rem 1.5rem;
    &--text {
      display: flex;
      justify-content: center;
      @extend .rd-search-btn-text;
    }
    &:active,
    &:focus,
    &:hover {
      box-shadow: none !important;
      background-color: $light-black !important;
      border: none;
    }
  }
  .fit {
    &-variation {
      margin-bottom: 1rem;
    }
    &-style {
      cursor: pointer;
    }
  }
}

.product-detail__stock-check {
  &__heading {
    top: 0;
    z-index: 2;
    margin-bottom: 0;
    position: sticky;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-bottom: 1.5rem;
    background-color: white;
    @extend .rd-store-heading;
  }
  &__link {
    display: flex;
    padding: 0.5rem 0;
    align-items: center;
    &__text {
      margin-left: 0.5rem;
      font-size: 0.875rem;
      font-weight: 400;
      text-decoration: underline;
      @extend .rd-store-heading;
    }
  }
}

.product-detail__stock-check__result__store-details-toggle:checked + .product-detail__stock-check__result__store-details {
  display: block;
}

.product-detail__stock-check__result {
  padding: 1rem;
  &__table {
    padding: 0.5rem 1rem 0;
    .product-detail__stock-check__result__store-details:last-of-type {
      border-bottom: 1px solid $WhiteChocolate;
    }
  }

  &:has(.product-detail__stock-check__result__row) {
    background-color: $slakelime;
  }

  &__row {
    justify-content: space-between;
    padding: 0.75rem 0;
    &--store {
      border-top: 1px solid $WhiteChocolate;
      border-bottom: 1px solid $WhiteChocolate;
    }
    .rd-chevron-down {
      margin-top: 0.063rem;
    }
  }

  &__store-details {
    padding: 0 1.5rem 1.5rem 1.5rem;
    &__distance {
      @extend .rd-store-text;
      margin-bottom: 0;
    }

    &__get-directions {
      @extend .rd-search-btn-text;
      color: $light-black;
      width: 100%;
      height: 2.5rem;
      border: 1px solid $light-black;
      background: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 1.5rem;
      margin-bottom: 0;
    }
  }

  .address-summary {
    @extend .rd-store-text;
    &__line {
      margin-bottom: 0 !important;
    }
    margin-bottom: 2rem;
  }

  &__col--store-name {
    margin-bottom: 0.5rem;
  }

  .stock-status-message {
    align-items: center;
    .icon-cross::before {
      font-size: 0.875rem;
    }
    .icon-check {
      @extend .rd-instock-tick;
      &::before {
        content: none;
      }
    }
    span {
      color: $grey-6;
      margin-left: 0.5rem;
      @extend .rd-store-text;
    }
  }
  .store-work-times {
    display: block !important;
    margin-bottom: 2rem;
    &__row {
      margin-bottom: 0.75rem;
      &__col {
        padding: 0;
      }
    }
  }
}
