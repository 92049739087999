.checkout-shipping {
  &__method-name,
  &__method-price {
    font-size: $fontsize-large;
  }
  &__type {
    &.mb-3 {
      margin-bottom: 0 !important;
    }
  }
}

.checkout-step {
  &__subheading {
    &--shipping {
      display: none;
    }
  }
}

.saved-stores,
.hubbox-container {
  .form-hubbox {
    #hubbox-search-button:not(:disabled):not(.disabled):active {
      background-color: $light-black;
      border-color: $light-black;
    }
  }
}
