.rd {
  &-breadcrumb {
    @include breadcrumb-atom();
  }
  &-breadcrumb-atom-top {
    @include breadcrumb-atom(0.625rem, 400, 196%, 0.03125rem, #000, uppercase);
  }
  &-breadcrumb-atom-bottom {
    @include breadcrumb-atom(0.6875rem, 400, 178.182%, 0.03438rem, #000, uppercase);
  }
}
