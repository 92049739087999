.cart-page {
  .cart-item {
    .line-item-quantity {
      &__quantity-selector {
        padding-left: rem-calc(40);
      }

      .form-group {
        margin-bottom: rem-calc(5);

        label {
          font-size: $fontsize-medium;
          top: rem-calc(10);
        }
      }
    }
  }

  .apple-pay-store-delivery-msg {
    padding-left: 10px;
    padding-right: 10px;
  }

  .cart-left {
    .dw-apple-pay-button.apple-pay-cart,
    .dw-apple-pay-button.apple-pay-cart:hover {
      margin-top: 0;
      margin-bottom: 0;
    }
    .apple-pay-store-delivery-msg {
      margin-top: 0;
      margin-bottom: 16px;
    }
  }

  .checkout-and-applepay {
    .dw-apple-pay-button.apple-pay-cart,
    .dw-apple-pay-button.apple-pay-cart:hover {
      margin-top: 0;
    }
    .apple-pay-store-delivery-msg {
      margin-top: 0;
    }
  }

  .privacy-notice {
    color: $dark-charcoal;
  }
}

.wishlist__products {
  .remove-from-wishlist {
    color: $light-black;
  }
}

.klarna-cart-osm {
  min-height: 5.75rem;
  letter-spacing: normal;

  @include media-breakpoint-up(lg) {
    min-height: 8.125rem;
  }

  @include media-breakpoint-up(xl) {
    min-height: 5.75rem;
  }
}

.paypal-cart-osm {
  min-height: 2.5rem;
}
