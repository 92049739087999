.wishlist {
  .product-info {
    @include media-breakpoint-up(md) {
      @include make-col(4);
    }
  }
  .navigation-link {
    color: $light-black;
  }
}
