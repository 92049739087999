.defaultDE {
  word-wrap: break-word;
  white-space: normal;
  word-break: normal;
  hyphens: auto;

  .cart-page {
    .cart-item {
      .product-card__content_qty .line-item-quantity {
        .line-item-quantity__quantity-selector {
          padding-left: 4rem;
        }

        @include media-breakpoint-down(sm) {
          max-width: 15rem;
        }
      }
    }

    .cart-promo {
      .cart-promo-section-input {
        margin-left: 0.5rem;
        max-width: 62%;
        flex: 0 0 62%;
        label {
          font-size: 14px;
        }
      }

      &-section {
        &-button.promo-code-submit {
          max-width: 35%;
          flex: 0 0 38%;

          .promo-code-btn {
            display: flex;
            padding: 0;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  .checkout-login {
    &__col {
      .card {
        .page-title {
          max-width: fit-content;

          @include media-breakpoint-down(xs) {
            padding: 0 0.25rem;
          }
        }
      }
    }
  }

  #footer {
    .contacts {
      .footer-contacts-link {
        height: 100%;
        float: left;

        &:nth-child(odd) {
          margin-left: 0.25rem;
        }

        a {
          min-height: 150px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          @include media-breakpoint-down(xs) {
            min-height: 175px;
          }
        }
      }
    }
  }

  .header {
    &-item {
      &__controls {
        &--right {
          @include media-breakpoint-down(md) {
            .header-element__menu .nav-menu__link__text,
            .header-element__user .header-element__text,
            .header-element.minicart .minicart-label {
              display: none;
            }
            .header-element.minicart .minicart-total {
              text-align: center;
            }
          }
        }
      }
    }
  }

  .nav-menu {
    &__items {
      &--level-2 li {
        white-space: normal !important;
      }
    }
  }

  .search-results {
    &-container {
      .product-tiles {
        .product-tile {
          &__action {
            text-transform: capitalize;
          }
          &__add-overlay-size-btn .add-to-cart {
            text-transform: capitalize;
          }
        }
      }
    }
  }

  .checkout {
    .summary__totals-grand-total-container {
      @include media-breakpoint-up(md) {
        .summary__totals-start-line,
        .summary__totals-end-line {
          padding-left: 0.25rem;
          padding-right: 0.25rem;
        }
      }
    }
  }
}
