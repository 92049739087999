@include media-breakpoint-up(lg) {
  .glide__arrows {
    .glide__arrow {
      top: 47%;
      transform: translateY(-50%);
      border-radius: 0;
      background-repeat: no-repeat;
      padding: 20px;
      background-position: center;
    }
    .glide__arrow--left {
      left: 0;
      background-image: url('../../../../static/default/images/icons/prev.svg');
      &:hover {
        opacity: 1;
      }
    }
    .glide__arrow--right {
      right: 0;
      background-image: url('../../../../static/default/images/icons/next.svg');
      &:hover {
        opacity: 1;
      }
    }
  }
}
