.heading {
  text-align: center;
  margin-bottom: 1rem;
  font-family: 'Chronicle Display';
  font-weight: 275;
  @media (max-width: $sm-width) {
    font-size: 1.5rem;
  }
}

.featureTile_content {
  background: #f8f6f3;
  padding: 3rem 6.5rem;
  margin-bottom: 3.125rem;

  @include media-breakpoint-down(md) {
    margin-bottom: 2.5rem;
    padding: 2.5rem 1rem;
    .tile_content {
      overflow-x: auto;
    }
  }

  img {
    width: 100%;
  }

  @include media-breakpoint-down(xs) {
    padding: 2rem 0 2.5rem 1rem;
  }

  .tile_info {
    padding: 0 !important;
    margin-right: 0.5rem;

    @include media-breakpoint-down(sm) {
      flex: 0 0 auto;
      max-width: 15rem;
      margin-right: 1rem;
    }
  }

  .img-section {
    margin-bottom: 0.5rem;
  }

  .text-section {
    @extend .rd-featureText;
    padding-bottom: 1.25rem;
  }
}
