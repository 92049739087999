$active-btn-selector: ":not(:disabled):not(.disabled):active";

.btn,
button {
  &.btn-secondary {
    &:hover {
      background-color: $light-black;
      border-color: $light-black;

      @include media-breakpoint-up(lg) {
        background-color: $light-black;
        border-color: $light-black;
      }
    }
    &#{$active-btn-selector} {
      background-color: $light-black;
      border-color: $light-black;
    }
  }
}
