.style_with_product_recs_container {
  .product-tile__image {
    margin-bottom: 0.5rem;
    @media (min-width: $bp-large) {
      margin-bottom: 0.25rem;
    }
  }
  .product-tile__body {
    padding: 0;
    .product-tile__name {
      margin-bottom: 0;
    }
  }
  .product-tile__price,
  .product-tile__name {
    @extend .rd-recommendation-tile-product-name;
  }
  .product-tile__rating {
    color: $black !important;
    margin: 0.25rem 0 !important;
    .font-icon {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      font-size: 1rem;
      &.icon-fa-star-o.rating-star-empty {
        color: $black;
      }
    }
  }
  .glide__arrows .glide__arrow--right {
    right: 1rem;
  }
  .rd-recommendation-heading {
    h5 {
      font-weight: 400;
    }
  }
  .product-detail__tile-row {
    @media (min-width: $bp-large) {
      position: relative;
    }
  }
  .rd-recommendation-heading,
  .product-detail__tile-row-container {
    margin-left: 0.375rem;
    @media (min-width: $bp-large) {
      margin-left: 0.875rem;
      padding-left: 0.875rem;
      padding-right: 0.875rem;
    }
  }
}
