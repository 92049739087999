.track-order {
  @include make-container();
  @include make-container-max-widths();

  .trackorder-fields {
    @include media-breakpoint-up(md) {
      @include make-col(8);
      @include make-col-offset(2);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(4);
      @include make-col-offset(4);
    }
  }
}
