@import "../components/checkout/pages/billing";
@import "../components/adyenCss";

.checkout-header {
  .logo {
    max-width: rem-calc(170);

    @include media-breakpoint-up(lg) {
      max-width: none;
    }
  }
}
.checkout {
  &__content {
    &-wrapper {
      margin-top: 2rem !important;
    }
  }

  &__step {
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);
  }

  .container {
    @include media-breakpoint-up(lg) {
      max-width: 1100px;
    }
  }

  .address-fields .form-group {
    margin: 0 0 rem-calc(24);
  }

  fieldset {
    legend {
      display: block;
      &.checkout-shipping__methods-title {
        margin-bottom: 0;
      }
    }
    &.inset-legend {
      padding: map-get($spacers, 4) map-get($spacers, 3);
      border: none;
    }
  }
}
