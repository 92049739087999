.back-to-tops {
  @extend .rd-back-to-tops;
  width: fit-content;
  height: 1.0625rem;
  margin: auto;
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
  text-underline-position: under;
}
