.product-desc-tab-container {
  margin-bottom: 1rem;
  li {
    padding: 0;
  }
}

.product-desc-tab-body {
  padding: 1.25rem 1rem 1.75rem 1rem;
  background-color: $slakelime;
  letter-spacing: 0.026rem;
  line-height: 150%;
  @extend .rd-back-to-tops;
  text-transform: unset;
  li,
  p {
    color: $grey-5;
    list-style-type: square;
    @extend .rd-global-banner-text;
    text-transform: initial;
  }
  ul {
    padding-left: 1rem !important;
  }
  li {
    margin-bottom: 0.375rem;
  }
  .material {
    a {
      color: $grey-5;
      font-weight: 700;
    }
  }
  table {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
    td {
      line-height: 150%;
      letter-spacing: 0.026rem;
      border: none !important;
      background-color: $white;
      @extend .rd-back-to-tops;
    }
  }
  &:has(.material.active) {
    padding: 1.5rem 1rem;
  }

  .style-code {
    font-size: 0.75rem;
  }

  .product-copy-label {
    text-transform: lowercase;
    display: inline-block;
    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.Customer-Review-Rating-tab {
  align-items: center;
  display: flex;
  justify-content: space-between;
  .customer-cta-rating {
    align-items: center;
    .icon-fa-star,
    .icon-fa-star-half-o,
    .icon-fa-star-o {
      width: 1rem;
      height: 1rem;
    }
  }
}
