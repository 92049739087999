.saved-addresses {
  &__card {
    @include media-breakpoint-up(md) {
      @include make-col(4);
    }
  }
}

.save-address {
  &__form-wrapper {
    text-align: left;

    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }
  }
}
