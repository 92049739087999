.rd-sticky-atb-desktop {
  background: $light-gray-90-opacity;

  .sticky-atb-container {
    flex-wrap: nowrap;

    @media (min-width: $bp-large) {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      margin-right: 1.5rem;
    }

    @media (max-width: $bp-medium-max) {
      margin-right: 0;
    }

    @media (min-width: $bp-desktop-min) {
      margin-right: 6.5rem;
    }

    .rd-sticky-atb-wishlist-atom .stickyBarBagButton.inactive,
    .rd-sticky-atb-cart-atom .stickyBarBagButton.inactive {
      display: none;
    }

    .stickyViewToBag {
      &.btn-secondary {
        padding: 0.5rem 1.5rem;
        background: #fff;
        border: 1px solid $light-black;
        color: $light-black;

        &:hover {
          opacity: 0.7;
        }
        &:active {
          background-color: $white;
          border-color: $black;
        }

        @media (max-width: $bp-medium-max) {
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: none;
          border-left: none;
        }
      }
    }
  }

  .sticky-atb__product-name--text {
    @extend .rd-sticky-atb-product-name;
    margin-right: 2rem;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: auto;
  }

  a.stickyViewToBag,
  button.stickyAddToBag,
  .rd-sticky-atb-cart-atom {

    @media (max-width: $bp-medium-max) {
      width: 100%;
    }

    @media (min-width: $bp-large) and (max-width: $bp-med-max) {
      width: 12rem;
    }

    @media (min-width: $bp-desktop-min) {
      width: 18.875rem;
    }
  }

  a.stickyViewToWishlist,
  button.stickyAddToWishlist,
  .rd-sticky-atb-wishlist-atom {

    @media (max-width: $bp-medium-max) {
      width: 6.625rem;
    }

    @media (min-width: $bp-large) {
      width: 3rem;
    }
  }

  &.stickyBarMenu {
    position: fixed;
    bottom: 0;
    right: 0;
    transform: translateY(100%);
    transition: transform 0.1s linear;
    z-index: 15;

    .btn-secondary.stickyAddToBag {
      padding: 0.5rem 1.5rem;
      background-color: $light-black;
      border: none;

      &:hover {
        opacity: 0.6;
      }
      &:active {
        background: $black;
      }
    }
    .btn {
      height: 3rem;
      font-size: 0.875rem;
      line-height: 150%;
      letter-spacing: 0.088rem;

      @media (min-width: $bp-large) {
        height: 2.5rem;
      }
    }
  }

  &.stickyBarMenuShow {
    transform: translateY(0);
  }

  .stickyBarBagButtonWishlist {
    position: absolute;
    line-height: 1;
  }

  .stickyAddToWishlist.active {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .stickyViewToWishlist {

    .viewBtnContainer {
      @media (max-width: $bp-medium-max) {
        display: flex;
        flex-wrap: nowrap;
        gap: 0.5rem;
      }

      .viewBtnText {
        @extend .rd-view-wishlist-cta-label;
        display: none;

        @media (max-width: $bp-medium-max) {
          display: flex;
          align-items: center;
        }
      }
    }

    &.active {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
    }
  }

  .rd-sticky-atb-wishlist-atom {
    .stickyViewToWishlist.btn-secondary,
    .stickyAddToWishlist.btn-secondary {
      border-color: $light-black;
      border-top: 1px solid $light-black;
      border-right: 1px solid $light-black;
      border-bottom: 1px solid $light-black;
      background-color: $white;
      border-left: none;

      &:focus {
        box-shadow: none;
      }
      &:hover {
        opacity: 0.7;
      }
      &:active {
        background-color: $white;
        border-color: $black;
      }

      @media (max-width: $bp-medium-max) {
        padding: 0.5rem;
        border-bottom: none;
        border-right: none;
      }
    }

    @media (max-width: $bp-medium-max) {
      flex: 0 0 6.625rem;
    }

    @media (min-width: $bp-large) {
      flex: 0 0 3rem;
    }
  }

  .stickyAddToWishlist,
  .stickyViewToWishlist {
    display: none;
  }
}
