.product-card {
  &__content {
    &__heading {
      font-weight: bold;
    }
  }

  &__price {
    &__promo {
      margin-top: map-get($spacers, 2);
    }
  }
}
