.fit-variation {
  margin-bottom: 1.5rem;
  @include media-breakpoint-down(md) {
    margin-bottom: 1rem;
  }
  .fit-style {
    border: 1px solid #d8d8d8;
    padding: 0.375rem 1rem;
    flex: 0 0 24%;
    max-width: 100%;
    text-align: center;
    margin-right: 0.5rem;
    span {
      @extend .rd-fitValuetext;
    }
  }

  .outOfStock {
    position: relative;
    background-image: none;
    cursor: pointer;
    border: 1px solid $neutral-grey-5;
    color: $neutral-grey-5;

    span {
      color: #4f4f4f;
    }

    &::before {
      content: '';
      position: absolute;
      top: -4px;
      left: 0;
      transform: rotate(3deg);
      background-size: cover;
      @extend .rd-outofstock-sign;
    }
  }

  .selectedFitVariant.outOfStock {
    color: $neutral-grey-5;
    border: 1px solid $neutral-grey-5;

    span {
      font-weight: normal;
    }
  }

  .selectedFitVariant {
    border: 1px solid $black;
  }
}

.attribute_section.active {
  .product-detail__attribute__error {
    display: none;
  }
}

.attribute_section {
  .color_label {
    margin-bottom: 0.5rem;
  }

  .color-section {
    margin-bottom: 1.5rem;
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }
  }

  p {
    margin-bottom: 0;
    padding-bottom: 0.25rem;
    margin-right: 0.438rem;
    position: relative;

    img {
      width: 1.875rem;
      height: 1.188rem;
    }
  }

  .attribute_value {
    @extend .rd-colorText;
  }

  .attribute_current {
    border-bottom: 1px solid $black;
  }

  .attribute_unselectable {
    color: #dee2e6;
    &::before {
      content: '';
      position: absolute;
      transform: rotate(-14deg);
      top: 1px;
      left: -2px;
      background-size: contain;
      width: 2.125rem;
      height: 1.25rem;
      @extend .rd-outofstock-sign;
    }
  }
}
