.checkout-billing {
  &__payment-input:not(:checked) ~ &__payment-selector--SAVED_CREDIT_CARD::after {
    background-color: $white;
  }

  &__submit-btn-wrapper {
    @include media-breakpoint-up(md) {
      @include make-col(10);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(8);
    }
  }

  &__payment {
    border: none;
    &-form--GIFT_CARD {
      padding-left: 0;
      padding-right: 0;

      #pin,
      #giftCardNumber {
        height: 48px;
      }

      .gc-content__cancel-link {
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0.36px;
        text-transform: uppercase;
      }

      .gc-content__description {
        color: $neutral-grey;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.42px;
      }

      &--verify_gc {
        input.form-control {
          border: 1px solid $grey-4;
          &[placeholder] {
            @include gift-form-font();
          }
        }
        button.btn-secondary {
          line-height: 30px;
          letter-spacing: 0.22px;
          &:disabled {
            border: none;
            color: $neutral-grey;
            background-color: $neutral-grey-5;
          }
          @include media-breakpoint-up(md) {
            max-width: 311px;
            margin: auto;
          }
        }
      }
      &--redeem_gc {
        @include media-breakpoint-up(md) {
          max-width: 311px;
        }
        .btn:disabled {
          border: none;
          color: $neutral-grey;
          background-color: $neutral-grey-5;
        }
      }
      .has-float-label input {
        margin-bottom: 0;
      }
    }
  }
  &__giftcard-list {
    @include media-breakpoint-up(md) {
      max-width: 311px;
      margin: auto;
    }
    &-text {
      color: $neutral-grey;
      line-height: 21px;
      letter-spacing: 0.42px;
      &.remaining_balance_text {
        font-weight: 700;
      }
    }
    a {
      font-size: 12px;
      letter-spacing: 0.36px;
      text-transform: uppercase;
    }
    .text-right {
      letter-spacing: 0.42px;
      font-weight: 600;
      color: #4f4f4f;
      margin-bottom: 0;
    }
    .btn {
      color: $light-black;
      font-size: 14px;
      border: 1px solid $light-black;
      letter-spacing: 0.224px;
      background-color: white;
    }
  }
  .payment-method--has-submethods {
    border: none;
    li.paymentMethod {
      border: 1px solid #dee2e6;
      margin-bottom: 1rem;
    }
    .checkout-billing__payment-indicator {
      display: none;
    }
  }
  &__address {
    padding: 0 !important;
    &-card {
      padding: 0 16px !important;
      @include media-breakpoint-up(lg) {
        padding: 0 56px !important;
      }
      &__heading {
        padding: 8px 0 24px 0;
        font-style: 400;
        font-weight: 700;
        line-height: 19.6px;
        letter-spacing: 0.42px;
        margin-bottom: 0;
      }
      .has-float-label.form-row-checkbox {
        margin-bottom: 16px;
        label {
          left: 13px;
          top: 0;
        }
        .custom-control-label::before {
          content: none;
        }
        input[type=checkbox]:checked ~ .form-icon {
          content: url('../../../../../../static/default/images/check-box-fill-payment-form.svg');
          width: unset;
          height: unset;
          border: none;
          margin-top: 0;
        }
      }
      .has-float-label.form-row-checkbox input[type=checkbox]:not(:checked) ~ .form-icon {
        content: url('../../../../../../static/default/images/unchecked-check-box-payment-form.svg');
        left: 0;
        margin-top: 0;
      }
    }
  }
}
.enter-new-details {
  margin-bottom: 16px;
  @include payment-form-font();
  text-transform: uppercase;
}

.checkout-payment .checkout-billing__selector-heading {
  position: relative;
  left: 40px;
  margin-left: unset;
}
