.confirmation {
  &__logout-container {
    &__link {
      @include media-breakpoint-up(lg) {
        @include make-col(3);
      }
    }
  }
}
.order-content {
  .order-details {
    &__summary-item {
      &--shipping-address,
      &--billing-address {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
