.edit-profile {
  &__form-outer-wrapper {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }
  &__form-wrapper {
    max-width: rem-calc(450);

    .form-group {
      width: 100%;
      padding: 0;
    }
  }
}
