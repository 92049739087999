.rd-breadcrumb.breadcrumb-bottom {
  .breadcrumb-container {
    @extend .rd-breadcrumb-atom-bottom;
    padding-left: 0;
    padding-bottom: 0.5rem;
    padding-top: 0;

    .breadcrumb {
      padding: 0;
      display: inline-block;

      .breadcrumb-item {
        display: inline;
        &::before {
          padding-right: 0;
          padding-left: 0.2rem;
        }
      }
    }
  }
}

.rd-breadcrumb.breadcrumb-top {
  .breadcrumb-container {
    @extend .rd-breadcrumb-atom-top;
    padding-left: 0.5rem;

    @media (min-width: $bp-desktop-min) {
      padding-left: 0;
    }

    .breadcrumb {
      padding: 0;
      justify-content: flex-start;
      &-item {
        &:last-child {
          display: none;
        }
      }
    }
  }
}
