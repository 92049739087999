// Product Badge
.product-detail__brand-badge {
  @extend .rd-productbadge;
}

//Product Title
.product-detail__product-name {
  @extend .rd-productTitle;
  @include media-breakpoint-down(md) {
    font-size: 1.25rem;
  }
}

//Product Price
.product-detail__prices {
  @extend .rd-productPrice;

  .has-strike-through {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    letter-spacing: 0.02625rem;
    color: $light-red;
  }

  .bestprice_close-icon {
    position: relative;
    top: 0.25rem;
  }

  .strike-through {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    letter-spacing: 0.02625rem;
    text-decoration: line-through;

    .prefix {
      display: none;
    }

    .value {
      margin-left: 0.5rem;
    }
  }

  .bestprice {
    display: block;
    margin-top: 0.5rem;
    position: relative;
    top: 3px;
    table {
      width: 15rem;
      margin-top: 0.5rem;
    }
    span {
      font-size: 0.875rem;
      .value {
        color: $light-red;
        text-align: center;
      }
    }

    &__close-icon {
      position: relative;
      top: 3px;
    }

    .price-label {
      cursor: pointer;
      color: $neutral-grey;
      text-decoration: underline !important;
      font-size: 0.875rem;
      padding-right: 0.5rem;
      text-underline-position: under;
    }
  }
}

//Product Promo
.product-detail__promotions {
  @extend .rd-productPromo;
  .callout {
    background-color: $light-red;
    padding: 0.25rem 0.5rem;
    width: max-content;
  }
}

.promoSection {
  .product-detail__brand-badge {
    display: none;
  }
}
