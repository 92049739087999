.checkout-billing {
  &__method-name {
    font-weight: 700;
  }

  &__payment-input[value=GIFT_CERTIFICATE] {
    &:checked {
      + .checkout-billing__payment-selector--GIFT_CERTIFICATE::after {
        content: url('../../../../static/default/images/chevron-up.svg');
        top: calc(50% - 0.75rem);
      }
    }
    + .checkout-billing__payment-selector--GIFT_CERTIFICATE::before {
      content: none;
    }
  }

  &__payment-input {
    &:checked + .checkout-billing__payment-selector {
      border: 1px solid $black;
      &::before {
        top: calc(50% - 0.35rem);
        height: 12px;
        width: 12px;
        margin-left: 0.4rem;
        border: none;
        left: unset;
      }
    }
  }

  &__selector-heading {
    margin-left: 30px;
  }

  &__payment {
    &:has(#paymentMethodsList) {
      margin-bottom: 0;
    }
    &-selector {
      border: 1px solid $grey-4;
      border-radius: 4px;
      justify-content: space-between;

      &.checkout-billing__payment-selector--PayPal,
      &.checkout-billing__payment-selector--DW_APPLE_PAY,
      &.checkout-billing__payment-selector--GIFT_CERTIFICATE {
        .checkout-billing__payment-selector-image {
          border: none !important;
          border-radius: unset !important;
        }
      }

      &:not(.checkout-billing__payment-selector--GIFT_CERTIFICATE) {
        &::after {
          left: unset;
          width: 24px;
          height: 24px;
          border-radius: 12px;
          top: calc(50% - 0.725rem);
        }
        &::before {
          border: none;
        }
      }

      .checkout-billing__payment-selector-image-container {
        &:not(.custom-image-container) .checkout-billing__payment-selector-image {
          width: 40px;
          border-radius: 4px;
          height: 28px !important;
          border: 1px solid $grey2;
        }
        &:has(.custom-image-container) {
          border: none;
        }
      }

      &--GIFT_CERTIFICATE {
        &::before {
          left: unset;
        }
        &::after {
          left: unset;
          border: none;
          top: calc(50% - 0.75rem);
          content: url('../../../../static/default/images/chevron-down.svg');
        }
      }
    }
    &-content--DW_APPLE_PAY {
      padding: 0 32px 3px 32px !important;
      margin-top: 16px;
      @media screen and (min-width: $md-width) {
        padding: 0 174px 3px 174px !important;
      }
    }
  }
}

#paymentMethodsList {
  .paymentMethod {
    label#lb_scheme {
      @include media-breakpoint-down(xl) {
        .custom-selector-heading {
          flex: 0 0 45%;
        }
        .custom-image-container {
          max-width: 47%;
          flex: 1 0 47%;
        }
      }
    }
    margin-bottom: 16px;
    .additionalFields {
      margin-top: 24px;
      padding-left: 16px;
      padding-right: 16px;
      @include media-breakpoint-up(lg) {
        padding-left: 56px;
        padding-right: 56px;
      }
      margin-left: 0;

      .adyen-checkout__input::placeholder,
      .adyen-checkout__label__text {
        padding-bottom: 8px;
        @include gift-form-font();
      }

      .adyen-checkout__label {
        margin-bottom: 16px;
      }

      .adyen-checkout__input,
      .adyen-checkout__checkbox__label {
        font-size: 14px;
        letter-spacing: 0.56px;
        line-height: 19.6px;
      }

      .adyen-checkout__card {
        &__holderName.adyen-checkout__field {
          margin-top: 0;
        }
        &__cvc__hint__wrapper {
          margin: 0 16px;
        }
        &__holderName__input {
          padding: 16px;
        }
        &__exp-date__input--oneclick {
          padding: 14px 8px;
        }
      }

      .adyen-checkout__input--focus,
      .adyen-checkout__input:active,
      .adyen-checkout__input:focus,
      .adyen-checkout__input:active:hover,
      .adyen-checkout__input:focus:hover,
      .adyen-checkout__input--focus:hover {
        border: 1px solid $grey-4;
        box-shadow: unset;
      }
      .adyen-checkout__field--storedCard label {
        margin-bottom: 8px;
      }

      .adyen-checkout__field {
        &--securityCode,
        &--expiryDate {
          margin-bottom: 8px;
        }
        &--cardNumber {
          margin-bottom: 24px;
        }
      }
    }
    .checkout-billing__method-name {
      @include payment-form-font();
    }
  }

  .adyen-checkout__store-details {
    .adyen-checkout__checkbox {
      margin-bottom: 16px;
      &__label {
        padding-left: 32px;
      }
    }

    .adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label {
      &::before {
        content: none;
      }
      &::after {
        height: 24px;
        width: 24px;
        background-color: unset;
        border: none;
        box-shadow: unset;
        content: url('../../../../static/default/images/unchecked-check-box-payment-form.svg');
      }
    }
    .adyen-checkout__checkbox__input:checked + .adyen-checkout__checkbox__label {
      &::before {
        content: none;
      }
      &::after {
        border: none;
        background-color: unset;
        box-shadow: unset;
        content: url('../../../../static/default/images/check-box-fill-payment-form.svg');
      }
    }
  }
}

.adyen-checkout__input-wrapper {
  .adyen-checkout__input {
    border-radius: 0;
    height: 48px;
    border-color: 1px solid $grey-4;
  }
}
