.rd {
  &-left-arrow {
    @include svg-icon();
  }

  &-style-with-cta-icon {
    @include svg-icon(1.5rem, 1.5rem, $black, center, contain);
    background-image: url('../../../../static/default/images/icons/stylewithCTA.svg');
  }

  &-close-icon {
    @include svg-icon(1.125rem, 1.125rem, $black, center, contain);
    background-image: url('../../../../static/default/images/icons/closeicon.svg');
  }

  &-size-guide-icon {
    @include svg-icon(1.5rem, 1.5rem, $black, center, contain);
    background-image: url('../../../../static/default/images/icons/Frame.svg');
  }

  &-down-arrow {
    @include svg-icon(1.125rem, 1.25rem, $black, center, contain);
    background-image: url('../../../../static/default/images/icons/arrowDown.svg');
  }

  &-outofstock-sign {
    @include svg-icon(100%, 2.5rem, $black, center, contain);
    background-image: url('../../../../static/default/images/icons/nostock.svg');
  }

  &-right-chevron {
    @include svg-icon(1rem, 1rem, $light-black, center, contain);
    background-image: url('../../../../static/default/images/icons/chevron_right.svg');
  }

  &-left-chevron {
    @include svg-icon(100%, 2.5rem, $light-black, center, contain);
    background-image: url('../../../../static/default/images/icons/chevron_left.svg');
  }

  &-instock-tick {
    @include svg-icon(1rem, 1rem, $light-black, center, contain);
    background-image: url('../../../../static/default/images/icons/tick.svg');
  }
  &-store-icon {
    @include svg-icon(1.5rem, 1.5rem, $light-black, center, contain);
    background-image: url('../../../../static/default/images/icons/blackstore.svg');
  }
  &-chevron-down {
    @include svg-icon(1rem, 1.125rem, $black, center, contain);
    background-image: url('../../../../static/default/images/icons/arrowDown.svg');
  }
  &-horizontal-filter-close {
    @include svg-icon(1.5rem, 1.5rem, $light-dark, center, contain);
    background-image: url('../../../../static/default/images/icons/horizontal_filter_close.svg');
  }
}
.customer-cta-rating {
  .icon-fa-star {
    @include svg-icon(0.5rem, 0.5rem);
    background-image: url('../../../../static/default/images/icons/full-star.svg');
  }
  .icon-fa-star-half-o {
    @include svg-icon(0.5rem, 0.5rem);
    background-image: url('../../../../static/default/images/icons/half-star.svg');
  }
  .icon-fa-star-o {
    @include svg-icon(0.5rem, 0.5rem);
    background-image: url('../../../../static/default/images/icons/empty-star.svg');
  }
}

.product-detail__fit-rating {
  .fit-marker {
    @include svg-icon(0.625rem, 0.625rem, #4F4F4F, center, contain);
    background-image: url('../../../../static/default/images/icons/fitmarker.svg');
  }
}
