.back-to-top {
  transition: transform 0.3s ease-in-out;
  transform: translateY(rem-calc(150));

  &.active {
    transform: translateY(0);
    display: none;
  }
}

[data-action="Search-Show"] {
  .back-to-top.active {
    display: flex;
  }
}
