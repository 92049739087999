.rd {
  &-primary {
    @include btn-primary();
  }

  &-secondary {
    @include btn-secondary();
  }

  &-btn-secondary {
    @include btn-colors($black,$black,$white);
  }
}
