.rd {
  &-spanElement {
    @include textElement();
  }

  &-productbadge {
    @include textElement(0.75rem, $font-weight: 500, $letter-spacing: 0.36px, $color: $light-black,  $text-transform: uppercase);
  }

  &-productTitle {
    @include textElement(1.125rem, $font-weight: 500, $text-transform: capitalize);
  }

  &-productPrice {
    @include textElement(0.875rem, $font-weight: 400, $letter-spacing: 0.42px, $line-height: 1.313rem);
  }

  &-productPromo {
    @include textElement(0.75rem, $font-weight: 400, $letter-spacing: 1.2px, $text-transform: uppercase, $color: $white);
  }

  &-fitValuetext {
    @include textElement(0.875rem, $font-weight: 400, $letter-spacing: 1.4px, $text-transform: uppercase);
  }

  &-colorText {
    @include textElement(0.875rem, $font-weight: 400, $letter-spacing: 0.42px, $text-transform: capitalize, $line-height: 1.313rem);
  }

  &-featureText {
    @include textElement(0.875rem, $font-weight: 400, $letter-spacing: 0.42px, $text-transform: capitalize, $line-height: 21px);
  }

  &-featureTitletext {
    @include textElement(1.125rem, $font-weight: 700, $letter-spacing: 1.08px, $text-transform: capitalize, $line-height: 1.375rem);
  }

  &-uspTitletext {
    @include textElement(0.875rem, $font-weight: 400, $letter-spacing: 0.0875rem, $text-transform: uppercase, $line-height: 1.3125rem);
  }

  &-uspInfoText {
    @include textElement(0.875rem, $font-weight: 400, $letter-spacing: 0.02625rem, $text-transform: capitalize, $line-height: 1.3125rem);
  }

  &-totalCount {
    @include textElement(0.875rem, $font-weight: 400, $letter-spacing: 0.02625rem, $text-transform: capitalize, $line-height: 1.3125rem);
  }

  &-reviewMsg {
    @include textElement(0.875rem, $font-weight: 400, $letter-spacing: 0.02625rem, $line-height: 1.3125rem);
  }

  &-customerReviewMessage {
    @include textElement(0.875rem, $font-weight: 400, $letter-spacing: 0.02625rem, $line-height: 1.3125rem, $text-transform: capitalize);
  }

  &-reviewItems {
    @include textElement(0.75rem, $font-weight: 400, $letter-spacing: 0.075rem, $line-height: 1.125rem, $text-transform: capitalize, $color: $black);
  }

  &-paginationItem {
    @include textElement(1.125rem, $font-weight: 500, $text-transform: capitalize, $line-height: normal);
  }
}
