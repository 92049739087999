.rd {
  &-h3 {
    @include heading();
  }
  &-customer-rating-cta-text {
    @include heading(0.75rem, 400, 150%, rem-calc(1.2), $light-black, capitalize);
  }
  &-style-with-CTA-text {
    @include heading(0.75rem, 400, 150%, rem-calc(1.2), $light-black, capitalize);
  }
  &-global-banner-text {
    @include heading(0.875rem, 400, 150%, rem-calc(0.42), $white, capitalize);
  }
  &-promo-banner-text {
    @include heading(0.75rem, 500, normal, rem-calc(0.36), $white, uppercase);
  }
  &-back-to-tops {
    @include heading(0.875rem, 400, normal, normal, #1d1d1d, capitalize);
  }
  &-usp-heading {
    @include heading(2rem, $text-transform: capitalize);
  }
  &-sticky-atb-cart-atom {
    @include heading(0.875rem, 400, 150%, 0.0875rem, #fff, uppercase);
  }
  &-sticky-atb-product-name {
    @include heading(1.125rem, 400, normal, normal, #000, capitalize);
  }
  &-featureTile-heading {
    @include heading(2rem, $text-transform: none);
  }
  &-view-wishlist-cta-label {
    @include heading(0.6875rem, 500, normal, normal, $light-black, uppercase);
  }
  &-product-detail__description-tab {
    padding-bottom: 0.5rem;
    display: block;
    text-align: center;
    border-bottom: 1px solid $black;
    @include heading(0.875rem, 400, 150%, 0.088rem, $light-black, uppercase);
    &:not(.active) {
      border-bottom: none;
      color: $grey-6;
    }
    &.active:hover {
      color: $light-black;
    }
  }

  &-reviewheading {
    @include heading($font-size: 1rem, $font-weight: 400, $line-height: normal, $letter-spacing: 0.1rem, $text-transform: uppercase);
  }
  &-customer-fit-rating-label {
    @include heading(0.875rem, 400, 150%, 0.02625rem, #4F4F4F, capitalize);
  }
  &-fits-small {
    @include heading(0.75rem, 400, 150%, 0.02625rem, #4F4F4F, none);
    flex-grow: 1;
  }
  &-prefect-fit {
    @include heading(0.75rem, 400, 150%, 0.02625rem, #4F4F4F, none);
    flex-grow: 1;
  }
  &-fits-large {
    @include heading(0.75rem, 400, 150%, 0.02625rem, #4F4F4F, none);
  }
  &-recommendation-heading {
    @media only screen and (max-width: $sm-width) {
      font-size: 0.938rem;
      letter-spacing: 0.094rem;
    }
    @include heading(1rem, 400, normal, 0.1rem, $light-black, uppercase);
  }
  &-recommendation-tile-product-name {
    @include heading(0.875rem, 400, 150%, 0.02625rem, $light-black, capitalize);
  }
  &-recently-viewed-rec-heading {
    @include heading(1rem, 400, normal, 0.1rem, $light-black, uppercase);
  }
  &-recently-viewed-product-name {
    @include heading(0.875rem, 400, 1.3125rem, 0.02625rem, $light-black, capitalize);
  }
  &-recently-viewed-product-price {
    @include heading(0.875rem, 400, 1.3125rem, 0.02625rem, $light-black, capitalize);
  }
  &-horizonral-bar-text {
    @media only screen and (min-width: $bp-large) {
      @include heading(0.813rem, 400, normal, 0.041rem, $dark-background, none);
    }
  }
  &-store-name {
    @include heading(0.875rem, 400, 1.3125rem, 0.02625rem, $light-black, none);
  }
  &-store-text {
    @include heading(0.875rem, 400, 1.225rem, 0.02625rem, $light-black, none);
  }
  &-nostock-heading {
    @include heading(0.875rem, 500, 1.225rem, normal, $light-black, none);
  }
  &-nostock-subheading {
    @include heading(0.75rem, 400, normal, 0.03rem, $light-black, none);
  }
  &-search-btn-text {
    @include heading(0.875rem, 400, 1.3125rem, 0.088rem, $white, uppercase);
  }
  &-store-heading {
    @include heading(1.125rem, 500, normal, normal,  $light-black, capitalize);
  }
  &-store-form-text {
    @include heading(1rem, 400, 1.4rem, 0.04rem, $light-dark, none);
  }
  &-store-invalid-text {
    @include heading(0.75rem, 400, 1.125rem, 0.038rem, $dark-red, none);
  }
  &-set-page-heading {
    @include heading(1.375rem, 400, normal, normal, $light-black, capitalize);
  }
  &-set-page-sub-heading {
    @include heading(0.9375rem, 400, normal, 0.094rem, $light-black, uppercase);
  }
  &-set-page-product-title {
    @include heading(1.25rem, 400, normal, normal, $light-black, capitalize);
  }
}
