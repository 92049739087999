@import "../atoms/button";
@import "../atoms/divider";
@import "../atoms/input";
@import "../atoms/image";
@import "../atoms/links";
@import "../atoms/icons";
@import "../atoms/label";
@import "../atoms/textElement";
@import "../atoms/lists";
@import "../atoms/breadcrumb";
@import "../atoms/pageElement";
@import "../atoms/fit-rating-scale";

@import "../molecules/backtotop";
@import "../molecules/customerRatingCTA";
@import "../molecules/stylewithCTA";
@import "../molecules/addTobag";
@import "../molecules/sizeGuide";
@import "../molecules/sizeDropdown";
@import "../molecules/fitRating";
@import "../molecules/breadcrumbs";
@import "../molecules/productInfo";
@import "../molecules/colorSwatch";
@import "../molecules/stickyATBdesktop";
@import "../molecules/featureTiles";
@import "../molecules/uspTiles";
@import "../molecules/productDescTab";
@import "../molecules/customerReviews";
@import "../molecules/styleWithRecommendation";
@import "../molecules/einsteinRecModule";
@import "../molecules/findInStore";
@import "../molecules/productSet";

//breakpoints
$desktop-min-width: 80rem;
$ipad-min-width: 62rem;
$mobile-min-width: 48rem;
$mobile-max-width: rem-calc(767);
$product-size-chart-pane-max-width: 90% !default;
$product-size-chart-pane-max-width-desktop: rem-calc(440) !default;
$product-content-details-width: rem-calc(420) !default;
$continuity-flyout-pane-max-width: 90% !default;
$flyout-style-headings: false !default;
$continuity-flyout-pane-max-width-desktop: rem-calc(440) !default;
$mobile-min-width: 501px;

.product-detail__details-section--product {
  flex: 0 0 50%;
  max-width: 50%;

  @include media-breakpoint-down(md) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@include media-breakpoint-down(md) {
  .new-pdp {
    .header-element.logo img {
      width: 9.44175rem;
      height: 1.77906rem;
    }
    // To do: Need to check & remove important
    header.header-sticky {
      transition: opacity 0.25s ease-in-out;
      position: fixed !important;
      @include media-breakpoint-up(lg) {
        position: sticky !important;
        top: 0;
      }
      @include media-breakpoint-down(md) {
        &.scroll-down {
          display: none;
          &.active-flyout {
            display: block;
          }
        }
      }
    }
  }

  .rd_PDP {
    .header .right-container {
      flex: auto;
      max-width: max-content;
    }

    .header {
      .header-item__menu {
        display: flex !important;
        flex: auto;
        max-width: max-content;
        align-items: center;
      }

      .minicart-icon__container .minicart {
        width: 20px;
        height: 21px;
      }

      .header-element__wishlist,
      .header-item__search {
        display: none !important;
      }
    }

    .header-element.logo.center {
      margin: 0 auto;
      max-width: rem-calc(180);
    }
  }
}

.product-detail {
  // New CSS
  padding-left: 0;
  padding-right: 0;

  .product-details-bottom-section-desktop {
    overflow-x: hidden;

    .waystowear {

      .heading h5 {
        color: $light-black;
        font-family: "Chronicle Display";
        font-size: 2rem;
        font-weight: 275;
        line-height: normal;

        @media (max-width: $bp-small-max) {
          margin-bottom: 1.5rem;
        }
      }

      @media (max-width: $bp-small-max) {
        margin-bottom: 1.5rem;

        .heading-mobile {
          display: block !important;

          h5 {
            font-size: 1.5rem;
          }
        }
      }

      @media (min-width: $bp-medium) {
        margin-top: 1rem;
        margin-bottom: 3.125rem;
      }

      .heading-desktop {
        @media (min-width: $bp-medium) {
          display: flex !important;
          margin-bottom: 0.5rem;

          h5 {
            margin-bottom: 0;
          }
        }
      }

      &-main-image-container {
        @media (max-width: $bp-small-max) {
          min-height: 458px;
          .carousel-item {
            img {
              width: -webkit-fill-available;
            }
          }

          &.carousel-inner {
            width: -webkit-fill-available;
            margin: 0 1.5rem;
          }
        }

        @media (min-width: $bp-medium) {
          min-height: 411px;
          .carousel-item {
            width: -webkit-fill-available;

            img {
              width: 100%;
              height: 100%;
            }

            .img-wrapper {
              position: relative;
            }
          }
        }

        .waystowear-look-count {
          display: none;
          color: $white;
          font-family: "Chronicle Display";
          font-size: 76px;
          font-weight: 325;
          line-height: normal;
          position: absolute;
          top: -0.8125rem;
          left: 0.5rem;

          @media (min-width: $bp-medium) {
            left: 0.3125rem;
          }
        }

        .carousel-item.active {
          .waystowear-look-count {
            display: block;
          }
        }
      }

      &-looks-container {
        gap: 1.5rem;

        @media (max-width: $bp-small-max) {
          padding-left: 1rem;
        }

        .waystowear-looks {
          color: $light-black;
          font-family: "Chronicle Display";
          font-size: 1.25rem;
          font-weight: 275;
          line-height: 1.375rem;

          &.active {
            .title {
              text-decoration: underline;
              text-underline-position: under;
            }
          }
        }

        .looks {
          cursor: pointer;
        }
      }

      &-sub-products:not(.active) * {
        opacity: 0;
        height: 0;
      }

      &-sub-products {
        opacity: 0;
        height: 0;

        @media (max-width: $bp-small-max) {
          margin: 0 0 0 1rem;
        }

        &.active {
          opacity: 1;
          height: auto;
          padding-top: 1.5rem;
        }

        .product-detail__tile-row .glide__slides {
          scrollbar-width: none;
        }

        .glide__arrow {
          &.glide__arrow--left {
            background-image: url('../../../../static/default/images/icons/chevron_left.svg');
            margin-left: 2rem;
          }

          &.glide__arrow--right {
            background-image: url('../../../../static/default/images/icons/chevron_right.svg');
          }

          &.glide__arrow--left,
          &.glide__arrow--right {
            background-color: transparent;
            border: none;
            box-shadow: none;

            &.glide__arrow--disabled {
              &:hover {
                opacity: 0.33;
              }
            }

            &:hover {
              border: none;
              background-color: transparent;
            }
          }

          @media (max-width: $bp-medium-max) {
            display: none;
          }
        }

        .recommendation-carousel-pdp {
          @media (max-width: $bp-large) {
            display: block;
          }
        }
      }

      .waystowear-left {
        padding-left: 0;
        padding-right: 0;

        @media (min-width: $bp-large) {
          padding-left: 1.5rem;
        }

        @media (min-width: $bp-medium) and (max-width: $bp-med-max) {
          padding-left: 1rem;
        }

        #pdpWaysToWearMainImgCarousel {
          @media (max-width: $bp-medium) {
            margin-bottom: 1.5rem;
          }
        }

        .primary-images__controls {
          &.carousel-indicators {
            z-index: 1;
            li {
              width: 8px;
              height: 8px;
              margin-left: 0.25rem;
              margin-right: 0.25rem;
              background-color: $light-black;
              border-radius: 50%;
              opacity: 0.2;

              &.active {
                opacity: 1;
              }
            }
          }
        }

        @media (min-width: $bp-medium) and (max-width: $bp-med-max) {
          flex: 0 0 41.67%;
          max-width: 41.67%;
        }

        @media (min-width: $bp-medium) {
          float: left;
        }
      }

      .waystowear-right {
        width: 100%;
        padding-left: 0;
        padding-right: 0;

        @media (min-width: $bp-medium) and (max-width: $bp-med-max) {
          flex: 0 0 58.33%;
          max-width: 58.33%;
        }

        @media (min-width: $bp-medium) {
          float: left;
          padding-left: 2rem;
        }
      }
    }
  }

  &__attribute__error {
    position: absolute;
    color: #bb271a;
    bottom: -1.75rem;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
  }

  &__addToCartStickyBar {
    position: fixed;
    bottom: 0;
    z-index: 5;
  }

  &__oos-message {
    display: none;
  }

  &__attribute {
    position: relative;
  }

  &__attribute--size,
  .cart-and-ipay {
    display: inline-block;
    width: 49%;
  }

  .giftcardbtn .cart-and-ipay {
    width: 100%;
  }

  .addtobagbtn .add-to-cart,
  .addtobagbtn .continuity-flyout__button {
    position: relative;
    left: 8px;
  }

  button.btn-secondary,
  .btn.btn-secondary {
    background-color: $black;
    border-color: $black;
    color: $white;
    height: 2.5rem;
    padding: 0;
    font-size: 0.875rem;
    letter-spacing: 0.0875rem;
  }

  .desktop-message {
    vertical-align: sub;
  }

  .continuity-flyout {
    @include flyout($continuity-flyout-pane-max-width, $continuity-flyout-pane-max-width-desktop);

    .btn-secondary {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .continuity-flyout__form {
      .continuity-flyout__button {
        left: 0;
      }
    }

    &__close-icon {
      padding-top: map-get($spacers, 2);
      padding-right: map-get($spacers, 2);
    }

    &__add-to-wishlist,
    .mobile-message {
      display: none;
    }

    &__container {
      padding: map-get($spacers, 3);
      position: absolute;
    }

    &__header {
      display: block;
      overflow: hidden;
    }

    &__title {
      float: left;
    }
  }

  @include media-breakpoint-down(md) {
    position: relative;
  }

  .details-space {
    @media (min-width: $ipad-min-width) {
      padding: 0 1.5rem;
    }

    @media (min-width: $desktop-min-width) {
      padding: 0 6.5rem;
    }
  }

  .max-content {
    max-width: 80rem;
    margin: 0 auto;
    width: 100%;
  }

  .waysToWear {
    padding: 0 0 rem-calc(48) 0;
    @media (min-width: $ipad-min-width) {
      padding: rem-calc(16) rem-calc(16) rem-calc(50) 0;
    }
    @media (min-width: $desktop-min-width) {
      padding: rem-calc(16) 0 rem-calc(50) 0;
    }
  }

  .featureTileSlot {
    @include media-breakpoint-up(lg) {
      padding-top: 0;
    }
  }

  .style_with_product_recs_container,
  .dynamicRecommendationSlot,
  .product-detail__recently-viewed {
    .heading {
      margin-top: 0;
    }
  }

  .spacer-bottom {
    padding-bottom: rem-calc(40);
    @include media-breakpoint-up(lg) {
      padding-bottom: rem-calc(50);
    }
  }

  .spacer-infocontent,
  .color-text,
  .swatch-container,
  .fit-container,
  .size-guide-title {
    margin-bottom: rem-calc(8);
  }

  .spacer-midcontent {
    margin-bottom: rem-calc(24);
    @media (max-width: $mobile-max-width) {
      margin-bottom: rem-calc(16);
    }
  }

  .pagination-review {
    padding: rem-calc(24) 0;
  }

  &__stock-check {
    padding: 0;
    &__link {
      text-align: left;
      margin-bottom: 0.9rem;
    }
  }
  @media (max-width: $mobile-max-width) {
    .product-details__accordion-container {
      margin-top: rem-calc(24) !important;
    }
  }

  .spacer-lastcontent {
    margin-bottom: rem-calc(16);
  }

  &__product-images {
    z-index: 0;
    padding: 0;
    @include media-breakpoint-up(lg) {
      padding-right: rem-calc(40);
      padding-left: 0;
      padding-bottom: rem-calc(40);
    }

    .primary-images {
      @media only screen and (max-width: $sm-width) {
        aspect-ratio: 5 / 7; // stylelint-disable-line
      }
    }
  }

  &__nav-row {
    padding: rem-calc(8) 0;
  }

  @include media-breakpoint-up(down) {
    .categories {
      padding-bottom: rem-calc(50);
    }
  }

  &__details-section {
    padding: rem-calc(12) rem-calc(16) rem-calc(24) rem-calc(16);
    @include media-breakpoint-up(lg) {
      padding: 0 0 rem-calc(50) rem-calc(40);
    }
  }

  &__rating {
    &__review {
      max-width: 100%;
    }
  }

  .continuity-flyout__pane.show .continuity-flyout__container {
    right: 0;
  }

  //Extra CSS need to remove

  .promo {
    background: $neutral-grey-5;
    padding: rem-calc(8);
  }

  .categories .product-detail {
    min-height: rem-calc(100);
    width: 100%;
    background: $neutral-grey-5;
  }

  .add-to-wish-list,
  .low_stock_message {
    display: none;
  }

  .primary-images__image-wrapper__inner {
    width: 100%;
    height: auto;
    aspect-ratio: 5 / 7; // stylelint-disable-line
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      padding-right: 10px;
    }
  }

  .add-to-cart,
  .css-ufbo4t {
    width: 100%;
  }

  &__content {
    min-height: rem-calc(300);
    width: 100%;
    background: $neutral-grey-5;
  }

  //END EXTRA

  //accordian

  &__accordion {
    .card,
    .card-header {
      border: none;
      &.border-bottom {
        margin-bottom: 0.1px;
      }
    }

    .size-chart {
      margin-left: 2rem;
      text-decoration: underline;
      text-transform: capitalize;
      text-underline-position: under;
    }

    .card-body {
      padding: map-get($spacers, 1) map-get($spacers, 5) map-get($spacers, 3) 0;

      ul {
        margin-top: rem-calc(26);
        line-height: 1.9;
      }
    }

    &-heading {
      .btn {
        text-decoration: none;
        text-transform: capitalize;

        i {
          top: 50%;
          transform: translateY(-50%);
          right: rem-calc(10);
        }

        &[aria-expanded="true"] {
          .close-icon {
            display: block;
          }

          .open-icon {
            display: none;
          }
        }

        &[aria-expanded="false"] {
          .close-icon {
            display: none;
          }

          .open-icon {
            display: block;
          }
        }
      }
    }

    &__product-details-delivery-returns {
      margin-bottom: 0;

      tr {
        background-color: $WhiteChocolate !important;
      }
    }
  }

  &__social-icons {
    text-align: center;
    padding: map-get($spacers, 2);
    margin-top: map-get($spacers, 2);
    margin-bottom: map-get($spacers, 2);

    &__callout {
      display: none;
    }

    li {
      display: inline-block;
    }

    i {
      font-size: rem-calc(21);
      color: $grey4;
      width: rem-calc(18);
      height: rem-calc(18);
    }
  }

  &__product-bottom {
    @include media-breakpoint-up(lg) {
      @include make-col(8);
    }
  }

  &__size-chart {
    @include flyout($product-size-chart-pane-max-width, $product-size-chart-pane-max-width-desktop, $flyout-style-headings);

    .size-guide-link {
      padding-right: map-get($spacers, 2);
    }

    &__close-label {
      display: none;
    }

    &__container {
      padding: map-get($spacers, 3);
      padding-bottom: map-get($spacers, 4);
    }

    &__content {
      font-size: $fontsize-large;

      .size-chart__measure-unit-switch {
        .radio-link {
          @include checkout-option;
          width: rem-calc(95);
          height: rem-calc(40);
          border: rem-calc(1) solid $border-color;

          &.active {
            @include checkout-selected-option;

            border: rem-calc(1) solid $secondary;
          }

          .radio-label {
            margin-left: map-get($spacers, 4);
          }
        }
      }

      .sizes-content-table {
        thead {
          tr {
            background-color: $grey3;
          }
        }
        tbody {
          tr:nth-child(even) {
            background-color: $grey3;
          }
        }
      }
    }
  }
  button.add-to-wish-list {
    &.active {
      &:focus {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
      }
    }
  }

  .style_with_product_recs_container {
    .product-recommendations__heading {
      h5 {
        font-size: 1rem;
        text-align: left;
        text-transform: uppercase;
      }
    }
    .glide__arrow {
      cursor: pointer;
      box-shadow: none;
      display: none;
      &.glide__arrow--left {
        left: 1rem;
        background-color: transparent;
        border: none;
        background-image: url('../../../../static/default/images/icons/chevron_left.svg');
      }

      &.glide__arrow--right {
        background-color: transparent;
        border: none;
        background-image: url('../../../../static/default/images/icons/chevron_right.svg');
      }

      &:hover {
        border: none;
        background-color: transparent;
      }

      @media (min-width: $bp-large) {
        display: block;
      }

      &.glide__arrow--disabled {
        &:hover {
          opacity: 0.33;
        }
      }
    }
  }
}

.header-banner.new-promo-banner {
  .header-banner-promo {
    display: none;
  }
}

.new-pdp {
  .header-banner {
    &.new-promo-banner {
      padding: 0.281rem 1rem;
      @include media-breakpoint-down(md) {
        padding: 0.281rem 1rem;
        background-color: $brown !important;
      }
      .header-banner-promo {
        display: none;
        a {
          color: $white;
        }
        @include media-breakpoint-down(md) {
          display: block;
        }
      }
    }
    &.new-global-banner {
      @include media-breakpoint-up(lg) {
        padding: 0.3125rem 1rem;
        &:has(.rd-global-banner-text > div:not(.carousel), .rd-global-banner-text p, .rd-global-banner-text a),
        &:has(.rd-global-banner-text .carousel-item) {
          background-color: $white !important;
        }
      }
      .header-banner-global {
        a {
          color: $black !important;
        }
        @include media-breakpoint-down(md) {
          display: none;
          a {
            color: $white;
          }
        }
        &-bottom {
          display: none;
        }
        .container {
          margin: 0 !important;
        }
      }
    }
    &:not(.new-global-banner) {
      .header-banner-global {
        display: none;
      }
    }
    .row {
      align-items: center;
    }
    .country-selector {
      &__current,
      &__current:not([href]):not([tabindex]) { // Overrides the Bootstrap reboot style
        color: $light-black;
        &:hover {
          color: $light-black;
        }
      }
    }
    .country-selector__current__selection {
      color: $light-black;
    }
  }

  .header-banner-bottom-new {
    display: block;
    padding-left: 0;
    padding-right: 0;
    &.header-banner-promo {
      display: block;
      text-align: center;
      background-color: $brown !important;
      padding: 0.5rem 1rem;
      @include media-breakpoint-down(md) {
        display: none;
      }
      a {
        color: $white;
        font-weight: 400;
      }
    }
    .header-banner-global-bottom {
      display: none;
      text-align: center;
      &.desktop {
        display: flex;
        justify-content: center;
      }
      a {
        color: $white;
        font-weight: 400;
      }
      .container {
        margin: 0 !important;
      }
      &.carousel-inner:has(div),
      &:has(> div:not(.carousel), p, a) {
        padding: 0.375rem 1rem;
        background-color: $brown !important;
        margin: 1rem 0;
      }
    }
  }
  .header-banner:not(.new-promo-banner) {
    min-height: unset;
    @media (max-width: $sm-width) {
      height: 0;
      .globale-selector,
      .js-page-locale-loader {
        display: none;
      }
    }
  }
  .product-details__social-icons-container {
    display: none;
  }
}

.header-banner-bottom-new.header-banner-promo,
.header-banner-bottom-new {
  display: none;
}

.product-detail__product-bottom.product-detail__product-bottom-new {
  flex: 0 0 auto;
  max-width: 100%;
}

.product-detail__bottom-recommendations__tab-content,
.product-detail__bottom-recommendations,
.product-detail__product-carousel {
  .container-new {
    max-width: 100%;
  }
}

.bottom-section-header {
  margin-top: rem-calc(56);
  @include media-breakpoint-up(md) {
    margin-top: rem-calc(88);
  }
}

@include media-breakpoint-down(md) {
  .product-breadcrumb.product-detail__nav-row__breadcrumbs {
    .breadcrumb-container {
      .breadcrumb-item {
        a:active,
        a:hover {
          color: #cdbc8d;
        }
      }
    }
  }
}

.klarna-pdp-osm {
  min-height: 8.125rem;
  letter-spacing: normal;

  /* need custom media query as klarna placeholder size changes at this width */
  @media (min-width: $mobile-min-width) {
    min-height: 5.625rem;
  }
}

.paypal-pdp-osm {
  min-height: 2.5rem;
}
