.style-with-CTA {
  @extend .rd-style-with-cta-icon;
  @extend .rd-style-with-CTA-text;
  display: flex;
  z-index: 1;
  padding: 0.25rem 0.5rem;
  gap: 0.25rem;
  border-radius: 3px;
  background: $overlay-white;
  position: absolute;
  top: 0.625rem;
  right: 1rem;
  height: 2rem;
  width: auto;
  align-items: center;
  cursor: pointer;

  @media (max-width: $bp-medium-max) {
    top: 3.125rem;
    z-index: 11;
  }
}

body.nav-menu-toggled--left .style-with-CTA {
  z-index: 1;
}
