.customer-rating-cta-container {
  @extend .rd-customer-rating-cta-text;
  cursor: pointer;
  padding: 0.1875rem 0.5rem;
  position: absolute;
  height: 2rem;
  background-color: $overlay-white;
  left: 4.5rem;
  top: 0.625rem;
  z-index: 1;
  display: none;
  flex-direction: column;
  justify-content: center;
  opacity: 0; /* Start with opacity 0 for fade-in effect */
  transition: opacity 1s ease;
  border-radius: 0.188rem;
  @media only screen and (max-width: $bp-medium-max) {
    top: 3.125rem;
    left: 1rem;
  }
}

.customer-cta-rating {
  gap: 0.25rem;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &::before,
  .icon-fa-star-o::before,
  .icon-fa-star::before,
  .icon-fa-star-half-o::before {
    content: none;
  }
}

.tab-content .rating-scroll {
  .rating__score {
    position: relative;
    top: 3px;
  }
}
