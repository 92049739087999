.checkout {
  .summary {
    border-left: none;
    border-right: none;

    &__products-heading {
      border-top: none;
    }

    &__products {
      padding-left: 0;
      padding-right: 0;
    }

    &__totals,
    &__totals-container,
    &__products-container {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 0 !important;
    }

    &__totals-grand-total-container {
      padding-left: 0;
      padding-right: 0;
      border-bottom: none;
      padding-top: 1rem !important;
    }
  }
}
