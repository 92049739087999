@include media-breakpoint-down(md) {
  .overflow-text {
    .overlay {
      position: fixed;
      z-index: 5;
      opacity: 0.4;
      background-color: $black;
      top: 0;
      width: 100%;
      bottom: 0;
      left: 0;
    }
  }
  .sizelist {
    max-height: 80%;
    overflow: scroll;
  }
}

@include media-breakpoint-down(sm) {
  .overflow-text {
    position: relative;
    overflow: hidden;
    z-index: 5;
    vertical-align: top;
    touch-action: none;
    -ms-touch-action: none;
  }
}

.accordion .size-guide-link {
  .size-guide-icon {
    position: absolute;
    @extend .rd-size-guide-icon;
  }
}

.cart-and-ipay {
  .add-to-cart {
    @extend .rd-btn-secondary;
    &:hover {
      @extend .rd-btn-secondary;
    }
  }
}

.select-dropdown__list.textSize {
  .size-value {
    padding-right: 0.313rem;
  }

  .select-dropdown__list-item {
    height: auto;
  }
}

.select-dropdown {
  position: relative;
  top: 3px;

  .sizeguidelabel {
    padding: 0.75rem 0 0.563rem 0.875rem;
    font-size: 1.125rem;
    .close-icon {
      position: absolute;
      right: 0.875rem;
      @extend .rd-close-icon;
    }
  }

  #notify-btn {
    margin-left: 0.5rem;
  }

  li.Selected {
    background-color: $slakelime;

    span:last-child {
      color: $light-dark;
    }
  }

  li.out-of-stock {
    pointer-events: none;

    .text-decoration-underline {
      pointer-events: all;
    }
  }

  li {
    span {
      line-height: 1.313rem;
      letter-spacing: 0.42px;
    }

    .disabled-item {
      text-decoration-line: line-through;
      opacity: 0.5;
    }

    .nostockmsg,
    .low-stock {
      color: $light-dark;
    }
  }

  .select-dropdown__button.active {
    &::after {
      transform: rotate(180deg);
    }
  }

  &__button {
    width: 100%;
    cursor: pointer;
    border: 1px solid $neutral-grey-5;
    background-color: $white;
    font-size: 1.125rem;
    text-transform: capitalize;
    font-weight: 500;
    color: $black;
    padding: 0 1rem;
    height: 2.5rem;

    &:focus {
      outline: none;
    }

    &::after {
      content: '';
      position: absolute;
      right: 1rem;
      top: 0.75rem;
      @extend .rd-down-arrow;
    }
  }

  .size-guide-l {
    list-style: none;
    padding: 0.25rem 0 0.563rem 1rem;
    cursor: pointer;
  }

  .text-decoration-underline {
    text-decoration: underline;
  }

  &__list {
    position: absolute;
    display: block;
    top: 2.375rem;
    left: 0;
    right: 0;
    padding: 0;
    opacity: 0;
    pointer-events: none;
    border: 1px solid $neutral-grey-5;
    background: $white;
    border-top: 0;
    transform-origin: top left;
    transform: scale(1, 0);
    transition: all ease-in-out 0.3s;
    z-index: 2;

    @include media-breakpoint-down(md) {
      position: fixed;
      top: 40%;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 16;
      transform-origin: bottom left;
      padding-bottom: 10rem;
      max-height: 80%;
    }

    &.active {
      opacity: 1;
      pointer-events: auto;
      transform: scale(1, 1);
    }
  }
  &__list-item {
    display: block;
    padding: 0.625rem 1rem;
    height: 2.5rem;
    background-color: $white;
    border-top: 1px solid $neutral-grey-5;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
  }

  &__list-item:last-child {
    border-bottom: 1px solid $neutral-grey-5;
  }
}
