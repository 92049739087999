@import "../components/accountNav";

.account-container {
  .account-heading {
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  .account-pages-heading {
    &__text {
      @include h3;

      text-align: center;
    }
  }
  .account-pages-copy {
    text-align: center;
  }
  .account-dashboard-content {
    .icon-wrap {
      border: 1px solid $light-black;
      .font-icon {
        color: $light-black;
      }
    }
  }
}
