.product-detail__recently-viewed,
.product-detail_you-might-also-like {
  width: 100%;
  @include einsteinRecModule();

  .product-detail__tile-row-container {
    padding-left: 0.875rem;
    margin-left: 0;
    padding-right: 0;

    @media (max-width: $bp-medium-max) {
      padding-left: 0.375rem;
    }

    .product-recommendations__heading {
      font-family: 'Lato';
      text-align: left;

      @media (max-width: $bp-small-max) {
        font-size: 0.9375rem;
        letter-spacing: 0.09375rem;
      }
    }

    .pdp-glide-container {
      position: relative;

      .glide__slide {
        @media (min-width: $bp-large) {
          max-width: 100% !important;
        }
      }
    }

    .product-tile__body {
      @media (max-width: $bp-medium-max) {
        padding-top: 0.5rem;
      }

      .product-tile__name {
        margin-bottom: 0;
      }
    }

    .glide__arrow {
      &.glide__arrow--left {
        margin-left: 0.5rem;
        background-image: url('../../../../static/default/images/icons/chevron_left.svg');
      }

      &.glide__arrow--right {
        margin-right: 0.5rem;
        background-image: url('../../../../static/default/images/icons/chevron_right.svg');
      }
    }
  }
}

.product-detail_you-might-also-like {
  .product-detail__tile-row-container {
    padding-left: 1.5rem;

    @media (max-width: $bp-medium-max) {
      padding-left: 1rem;
    }
  }
}

.product-detail__product-carousel:has(.product-detail__recently-viewed) {
  padding-right: 0;
}
