.saved-payments-list {
  &__edit,
  &__delete {
    color: $grey1;
    text-decoration: underline;
  }
  &__delete {
    &__icon {
      display: none;
    }
  }
}
