.product-detail__product-images {
  @media only screen and (max-width: $sm-width) {
    div[data-test="gallery-thumbnail-wrap"] {
      position: absolute;
      bottom: 0.5rem;
      z-index: 2;
    }
    div[data-test="gallery-spacer"] {
      display: none;
    }
  }
}
