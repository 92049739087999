.product-reviews.productreviews-section {
  height: auto !important;
}

.product-detail__rating__review-score {
  color: $black;
  text-align: left;
}

.product-detail__ratings {
  cursor: pointer;
}

.price_rating {
  .font-icon {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    font-size: 1rem;
  }

  .rating__count {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125rem;
    letter-spacing: 0.02625rem;
    text-transform: capitalize;
  }
}

[class*="font-icon icon-"]:active {
  color: $black;
}

.product-detail__rating__status--sort {
  margin-bottom: 0;
}

.product-detail__rating__status {
  border-bottom: 1px solid rgba(29, 29, 29, 0.20); // stylelint-disable-line
}

.reviewheading {
  @extend .rd-reviewheading;
  margin-bottom: 0.62rem;
}

.totalcount {
  @extend .rd-totalCount;
}

.review-msg {
  @extend .rd-reviewMsg;
}

.review_message {
  @extend .rd-customerReviewMessage;
  margin: 1.25rem 0;
}

.review-items:not(.reviewDate) {
  margin-right: 1.5rem;
}

.review-items {
  @extend .rd-reviewItems;
  color: rgba(29, 29, 29, 0.60);  // stylelint-disable-line
}

.product-reviews {
  margin-bottom: rem-calc(40);
  background-color: $slakelime;
  @include media-breakpoint-up(lg) {
    margin-bottom: rem-calc(50);
  }

  .font-icon {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    font-size: 1rem;
  }

  .has-float-label select {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125rem;
    letter-spacing: 0.02625rem;
    text-transform: capitalize;
    padding: 0 3rem 0 1rem;
    border: 1px solid rgba(29, 29, 29, 0.20); // stylelint-disable-line
    @include media-breakpoint-down(md) {
      padding: 0 3rem 0 1.6rem;
    }
  }

  select ~ .form-icon {
    right: 1.125rem;
  }

  .rating_status {
    margin: 1.5rem 0;
  }

  .has-float-label {
    width: 100%;
  }

  .backToTop {
    padding-top: 1.5rem;
    padding-bottom: 3rem;
  }

  .product-detail__rating__review {
    display: none;
  }

  .product-detail__rating__review.show {
    display: block;
    padding: 1.5rem 0;
    border-bottom: 0.0625rem solid rgba(29, 29, 29, 0.20); // stylelint-disable-line
  }

  .reviewSection {
    padding: 2.5rem 6.5rem 0 6.5rem;
    @include media-breakpoint-down(md) {
      padding: 2.5rem 1rem 0 1rem;
    }
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;

  .paging__page-icon {
    color: $light-black;
  }
}

#prev-btn {
  margin-right: 0.625rem;
  @include media-breakpoint-down(md) {
    margin-right: 0.5rem;
  }
}

.pagination-btn {
  border: 1px solid rgba(29, 29, 29, 0.20); // stylelint-disable-line
  background-color: transparent;
  padding: 0 1rem;
  cursor: pointer;
  height: 2.5rem;
  outline: none !important;
  position: relative;
  @include media-breakpoint-down(md) {
    width: 2rem;
    height: 2rem;
    padding: 0;
  }
  @include media-breakpoint-down(md) {
    .font-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .paging__page-text {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.pagination-btn:disabled {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.page-numbers {
  display: flex;
  align-items: center;
}

.page-number {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.063rem solid rgba(29, 29, 29, 0.20); // stylelint-disable-line
  margin-right: 0.5rem;
  cursor: pointer;
  @extend .rd-paginationItem;
  @include media-breakpoint-down(md) {
    width: 2rem;
    height: 2rem;
    font-size: 0.875rem;
  }
}

.page-number.active {
  background-color: $black;
  color: $white;
}

.page-number.disabled {
  cursor: not-allowed;
  background-color: transparent;
  border: 0;
  padding: 0;
  width: auto;
}
